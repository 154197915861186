import React, { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../GetToken";
import { handleInvalidToken } from "../../AuthUtils";
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../AnimationSpinner";

const ResetPassword = (props) => {
	const navigate = useNavigate();

	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [loading, setLoading] = useState(false)

	const handleSubmit = async (event) => {
		event.preventDefault();

		const token = GetToken();
		if (!token) {
			handleInvalidToken(navigate);
			return;
		}

		let isValid = true;
		if (!oldPassword || !newPassword || !confirmPassword) {
			Swal.fire("Please fill in all fields: Old Password, New Password, and Confirm Password.");
			isValid = false;
		} else {
			if (newPassword.length < 6 || newPassword.length > 12) {
				Swal.fire("New Password must be between 6 and 12 characters.");
				isValid = false;
			} else if (newPassword !== confirmPassword) {
				Swal.fire("New Password and Confirm Password do not match.");
				isValid = false;
			}
		}

		if (!isValid) {
			return;
		}
		setLoading(true)
		const formData = new FormData();
		formData.append("old_password", oldPassword);
		formData.append("new_password", newPassword);
		formData.append("confirm_password", confirmPassword);
		formData.append("token", token);
		formData.append("action", "changePassword");

		try {
			const response = await axios.post(
				`${props.base_url}api/user/get-user-resource`,
				formData
			);

			if (response.data.status) {
				setLoading(false)
				Swal.fire({
					icon: "success",
					title: "Password Updated",
					text: "Your password has been updated successfully.",
				});
				navigate("/dashboard", { replace: true });
			} else {
				setLoading(false)
				if (response.data.reset) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false)
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error.response?.data?.message || error.message || "Something went wrong. Please try again.",
			});
		}
	};

	return (
		<div className="right-container">
			<AnimationSpinner show={loading}/>
			<div className="content">
				<div className="container">
					<div className="page-title-box">
						<h4 className="page-title">Change Password</h4>
					</div>

					<div className="card-container">
						<div className="card">
							<div className="card-header">
								<h6 className="profile-heading">Change Password</h6>
							</div>
							<div className="card-body">
								<form onSubmit={handleSubmit}>
									<div className="row">
										<div className="col-lg-4 col-md-6 mb-4">
											<label htmlFor="oldPassword" className='mb-2'>Old Password</label>
											<input
												type="password"
												className="form-control"
												name="oldPassword"
												id="oldPassword"
												placeholder="Enter Old Password"
												onChange={(e) => setOldPassword(e.target.value)}
											/>
										</div>
										<div className="col-lg-4 col-md-6 mb-4">
											<label htmlFor="newPassword" className='mb-2'>New Password</label>
											<input
												type="password"
												className="form-control"
												name="newPassword"
												id="newPassword"
												placeholder="Enter New Password (6-12 characters)"
												onChange={(e) => setNewPassword(e.target.value)}
											/>
										</div>
										<div className="col-lg-4 col-md-6 mb-4">
											<label htmlFor="confirmPassword" className='mb-2'>Confirm Password</label>
											<input
												type="password"
												className="form-control"
												name="confirmPassword"
												id="confirmPassword"
												placeholder="Confirm New Password"
												onChange={(e) => setConfirmPassword(e.target.value)}
											/>
										</div>
										<div className="col-lg-12 card-footer">
											<button type="submit" className="btn btn-primary">
												Change Password
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
