import React from "react";
import './ForgotPassword.css'
import { Link } from "react-router-dom";

// material icon
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const ForgotPassword = () => {
	return (
		<>
			<div
				className="account-pages login-box lines"
				style={{ backgroundImage: "url('http://localhost/aistaff/assets/images/login-bg.png')" }}
			>
				<div className="account-pages login-box">
					<div className="container-fluid h-100">
						<div className="row align-items-center justify-content-between">
							<div className="col-lg-5 p-0">
								<div
									className="login-content"
									style={{ backgroundImage: "url('http://localhost/aistaff/assets/images')" }}
								>
									<div className="row justify-content-center align-items-center h-100">
										<div className="col-lg-10">
											<div className="login-box-inner">
												<div className="login-inner">
													<div className="account-logo-box">
														<Link to="/" className="text-success">
															<img
																src="http://localhost/aistaff/assets/images/logo-dark.png"
																alt="images"
															/>
														</Link>
													</div>
													<div className="login-head">
														<h2 className="title">Welcome To ChatZone AI</h2>
														<p className="sub-title">Forgot Your Password</p>
													</div>

													<form id="forgot_password">
														<div className="form-group mt-4">
															<label htmlFor="forgot-password-email">
																Your Email
															</label>
															<div className="form-group-inner">
																<div className="login-icon">
																	<MailOutlineIcon/>
																</div>
																<input
																	type="email"
																	name="email"
																	className="form-control"
																	placeholder="Enter your email address"
																	aria-label="email"
																	aria-describedby="email"
																	id="forgot-password-email"
																	required
																/>
															</div>
														</div>

														<div className="form-group mt-4 mb-0">
															<div className="g-recaptcha" data-sitekey=""></div>
														</div>

														<div className="form-group">
															<button type="submit" className="btn btn-primary">
																Send Request
															</button>
														</div>

														<div className="form-group text-right forget-link">
															Go Back
															<Link
																className="text-15px fw-700"
																to="/login"
															>
																{" "}
																Login{" "}
															</Link>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-7 d-none pr-0 d-lg-block">
								<div className="login-media">
									<div className="img-box">
										<img
											src="http://localhost/aistaff/assets/images/login-img.png"
											alt="images"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ForgotPassword;
