import React from 'react';
import { Navigate } from 'react-router-dom';

const GetToken = () => {
    const token = localStorage.getItem('aistafftoken'); 


	return token ? token : false;
};

export default GetToken;
