import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import AnimationSpinner from "../../../AnimationSpinner";

const Edit = (props) => {
	const navigate = useNavigate();
	const { departmentIds } = useParams();
	const [oldDepartmentLogo, setOldDepartmentLogo] = useState('');
	const [departmentName, setDepartmentName] = useState("");
	const [assignStaff, setAssignStaff] = useState([]);
	const [description, setDescription] = useState("");
	const [selectedCompany, setSelectedCompany] = useState("");
	const [logo, setLogo] = useState(null);
	const [companies, setCompanies] = useState([]);
	const [staffList, setStaffList] = useState([]);
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const fetchEditData = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true)
				const formData = new FormData();
				formData.append("action", "getEditDepartmentDetails");
				formData.append("token", token);
				formData.append("department_ids", departmentIds);

				const response = await axios.post(
					`${props.base_url}user/api/process-resource`,
					formData
				);

				if (response.data.status) {
					setLoading(false)
					setCompanies(response.data.companies || []);
					// setStaffList(response.data.staff || []);
					setOldDepartmentLogo(response.data.items.department_logo);
					setDepartmentName(response.data.items.department_name || "");
					setSelectedCompany(response.data.items.company_ids || "");
					setDescription(response.data.items.description || "");

					// const assignStaffArray = JSON.parse(response.data.items.assign_staff || "[]");
					// const staffIds = assignStaffArray.map(staff => staff.ids);
					setAssignStaff(response.data.assign_staff);
					const staffOptions = response.data.staff.map((staff) => ({
						value: staff.ids,
						label: staff.name,
					}));
					setStaffList(staffOptions || []);
				} else {
					if (response.data.reset) {
						setLoading(false)
						handleInvalidToken(navigate);
					} else {
						Swal.fire({
							icon: "error",
							title: "Error",
							text: response.data.message,
						});
					}
				}
			} catch (error) {
				setLoading(false)
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			}
		};

		fetchEditData();
	}, [departmentIds, navigate, props.base_url]);

	// useEffect(() => {
	// 	const fetchStaffListData = async () => {
	// 		try {
	// 			const token = GetToken();
	// 			if (!token) {
	// 				handleInvalidToken(navigate);
	// 				return;
	// 			}

	// 			const formData = new FormData();
	// 			formData.append("action", "getUserCompanyAndStaffLists");
	// 			formData.append("token", token);
	// 			formData.append("selected_company", selectedCompany);

	// 			const response = await axios.post(
	// 				`${props.base_url}user/api/process-resource`,
	// 				formData
	// 			);

	// 			if (response.data.status) {
	// 				const staffOptions = response.data.staff.map((staff) => ({
	// 					value: staff.ids,
	// 					label: staff.name,
	// 				}));
	// 				setStaffList(staffOptions || []);
	// 			} else {
	// 				if (response.data.reset) {
	// 					handleInvalidToken(navigate);
	// 				} else {
	// 					Swal.fire({
	// 						icon: "error",
	// 						title: "Error",
	// 						text: response.data.message,
	// 					});
	// 				}
	// 			}
	// 		} catch (error) {
	// 			Swal.fire({
	// 				icon: "error",
	// 				title: "Error",
	// 				text:
	// 					error.response?.data?.message ||
	// 					error.message ||
	// 					"Something went wrong. Please try again.",
	// 			});
	// 		}
	// 	};

	// 	fetchStaffListData();
	// }, [selectedCompany]);
	const handleLogoChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			setLogo(file);
		}
	};

	function Sweetalert(field) {
		Swal.fire({
			icon: "error",
			title: "Validation Error",
			text: field + " is required!",
		});
	}

	const handleSubmit = async (event) => {
		event.preventDefault();

		const token = GetToken();
		if (!token) {
			handleInvalidToken(navigate);
			return;
		}

		let isValid = true;
		if (!departmentName && !assignStaff && !selectedCompany) {
			Sweetalert("Department Name, Assign Staff & Company");
			isValid = false;
		} else {
			if (!departmentName) {
				Sweetalert("Department Name");
				isValid = false;
			}
			if (!assignStaff.length) {
				Sweetalert("Assign Staff");
				isValid = false;
			}
			if (!selectedCompany) {
				Sweetalert("Company");
				isValid = false;
			}
		}
		if (!isValid) {
			return;
		}

		const selectedStaff = staffList.filter(staff => assignStaff.includes(staff.value));
		setLoading(true)
		const formData = new FormData();

		if (logo) formData.append("department_logo", logo);
		formData.append("edit_department_ids", departmentIds);
		formData.append("department_name", departmentName);
		formData.append("assign_staff", JSON.stringify(selectedStaff));
		formData.append("description", description);
		formData.append("company_ids", selectedCompany);
		formData.append("token", token);
		formData.append("action", "updateDepartment");

		try {
			const response = await axios.post(
				`${props.base_url}user/api/process-resource`,
				formData
			);

			if (response.data.status) {
				setLoading(false)
				Swal.fire({
					icon: "success",
					title: "Department Updated",
					text: "Your department details have been updated successfully.",
				});
				navigate("/manage-department", { replace: true });
			} else {
				if (response.data.reset) {
					setLoading(false)
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false)
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	return (
		<Container className="h-100">
			<AnimationSpinner show={loading}/>
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card">
							<div className="form-body">
								<div className="head-part">
									<h4 className=" title">Edit Department Details</h4>
								</div>
						
								<form onSubmit={handleSubmit}>
							<div className="row w-100">
								<div className="col-md-12 mb-4">
									<label htmlFor="formDepartmentName" className="form-label">
										Department Name
									</label>
									<input
										type="text"
										id="formDepartmentName"
										className="form-control custom-form-control"
										placeholder="Enter department name"
										value={departmentName}
										onChange={(e) => setDepartmentName(e.target.value)}
									/>
								</div>
								<div className="col-md-6 mb-4">
									<label htmlFor="formCompany" className="form-label">
										Select Company
									</label>
									<select
										name="company"
										className="form-control custom-form-control"
										value={selectedCompany}
										onChange={(e) => setSelectedCompany(e.target.value)}
										disabled
									>
										<option value="">Select Company</option>
										{companies.map((company) => (
											<option key={company.ids} value={company.ids}>
												{company.company_name}
											</option>
										))}
									</select>
								</div>
								<div className="col-md-6 mb-4">
									<label htmlFor="formStaff" className="form-label">
										Select AI Staff
									</label>
									{/* <select
										name="staff"
										className="form-control"
										multiple
										value={assignStaff}
										onChange={(e) =>
											setAssignStaff(Array.from(e.target.selectedOptions, option => option.value))
										}
									>
										<option value="" disabled>Select AI Staff</option>
										{staffList.map((staff) => (
											<option key={staff.ids} value={staff.ids}>
												{staff.name}
											</option>
										))}
									</select> */}



									<Select
										isMulti
										key={selectedCompany}
										value={staffList.filter(staff => assignStaff.includes(staff.value))}
										name="Staff"
										onChange={(selectedOptions) =>
											setAssignStaff(selectedOptions.map(option => option.value))
										}
										options={staffList}
									/>

								</div>
							</div>

							<div className="mb-4">
								<label htmlFor="formDescription" className="form-label">
									Description
								</label>
								<textarea
									id="formDescription"
									className="form-control custom-form-control"
									style={{ height: "100px" }}
									placeholder="Enter company description"
									value={description}
									onChange={(e) => setDescription(e.target.value)}
								/>
							</div>

							<div className="mb-2">
								<label htmlFor="formLogo" className="form-label">
									Department Logo
								</label>
								<input
									type="file"
									id="formLogo"
									accept=".jpg,.png,.jpeg"
									className="form-control form-radius"
									onChange={handleLogoChange}
								/>
							</div>
							<div className="mb-2">
								<img className="img-fluid img-box mb-3 " src={oldDepartmentLogo} alt="Department Logo" />
							</div>
							<button type="submit" className="btn btn-primary mt-2 mb-3">
								Update Department
							</button>
								</form>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Edit;
