import React, { useState } from "react";
import "./Create.styles.css";
import backGroundImg from "../../../assests/images/create/poinbackground2.jpg";

import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../../AnimationSpinner";

const Create = (props) => {
	const navigate = useNavigate();

	const [companyName, setCompanyName] = useState("");
	const [category, setCategory] = useState("");
	const [description, setDescription] = useState("");
	const [logo, setLogo] = useState(null);
	const [loading, setLoading] = useState(false)

	const handleLogoChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			setLogo(file);
		}
	};

	function Sweetalert(field) {
		Swal.fire({
			icon: "error",
			title: "Validation Error",
			text: field + " is required!",
		});
	}
	const handleSubmit = (event) => {
		event.preventDefault();

		var token = GetToken();

		if (!token) {
			handleInvalidToken(navigate);
			return;
		}

		let isValid = true;
		if (!companyName && !category && !logo) {
			Sweetalert("Company Name, Category & Logo");
			isValid = false;
		} else {
			if (!companyName) {
				Sweetalert("Company Name");
				isValid = false;
			}
			if (!category) {
				Sweetalert("Category");
				isValid = false;
			}
			if (!logo) {
				Sweetalert("Logo");
				isValid = false;
			}
		}
		if (!isValid) {
			return;
		}
		setLoading(true)
		const formData = new FormData();
		formData.append("company_name", companyName);
		formData.append("category", category);
		formData.append("description", description);
		formData.append("company_logo", logo);
		formData.append("token", token);
		formData.append("action", "createCompany");

		axios.post(`${props.base_url}user/api/process-resource`, formData)
			.then((response) => {
				if (response.data.status === true) {
					setLoading(false)
					Swal.fire({
						icon: "success",
						title: "Company Added",
						text: "Your company details have been added successfully.",
					});
					navigate("/manage-company", { replace: true });
				} else {
					setLoading(false)
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			})
			.catch((error) => {
				setLoading(false)
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			});
	};

	return (
		<Container className="h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				{/* <div className="outer-container">
					<div className="form-container create-form shadow-lg ">
						<h4 className="text-center mb-3">Add Company Details</h4>
						<hr />
						<form onSubmit={handleSubmit}>
							<div className="row w-100">
								<div className="col-md-6 mb-4">
									<label htmlFor="formCompanyName" className="form-label">
										Company Name
									</label>
									<input
										type="text"
										id="formCompanyName"
										className="form-control"
										placeholder="Enter company name"
										value={companyName}
										onChange={(e) => setCompanyName(e.target.value)}
									/>
								</div>

								<div className="col-md-6 mb-4">
									<label htmlFor="formCategory" className="form-label">
										Category
									</label>
									<select
										id="formCategory"
										className="form-select"
										value={category}
										onChange={(e) => setCategory(e.target.value)}
									>
										<option value="">Select a category</option>
										<option value="Technology">Technology</option>
										<option value="Finance">Finance</option>
										<option value="Health">Health</option>
										<option value="Education">Education</option>
										<option value="Retail">Retail</option>
									</select>
								</div>
							</div>

							<div className="mb-4">
								<label htmlFor="formDescription" className="form-label">
									Description
								</label>
								<textarea
									id="formDescription"
									className="form-control"
									style={{ height: '100px' }}
									placeholder="Enter company description"
									value={description}
									onChange={(e) => setDescription(e.target.value)}
								/>
							</div>

							<div className="mb-4">
								<label htmlFor="formLogo" className="form-label">
									Company Logo
								</label>
								<input
									type="file"
									id="formLogo"
									accept=".jpg,.png,.jpeg"
									className="form-control"
									onChange={handleLogoChange}
								/>
							</div>

							<button type="submit" className="btn btn-primary mt-3">
								Add Company
							</button>
						</form>
					</div>
				</div> */}

				{/* page code */}
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card">
							<div className="form-body">
								<div className="head-part">
									<h4 className=" title">Add Company Details</h4>
								</div>
								
								<form onSubmit={handleSubmit}>
									<div className="row w-100">
										<div className="col-md-6 mb-4">
											<label htmlFor="formCompanyName" className="form-label">
												Company Name
											</label>
											<input
												type="text"
												id="formCompanyName"
												className="form-control custom-form-control"
												placeholder="Enter company name"
												value={companyName}
												onChange={(e) => setCompanyName(e.target.value)}
											/>
										</div>

										<div className="col-md-6 mb-4">
											<label htmlFor="formCategory" className="form-label">
												Category
											</label>
											<select
												id="formCategory"
												className="form-select custom-form-select"
												value={category}
												onChange={(e) => setCategory(e.target.value)}
											>
												<option value="">Select a category</option>
												<option value="Technology">Technology</option>
												<option value="Finance">Finance</option>
												<option value="Health">Health</option>
												<option value="Education">Education</option>
												<option value="Retail">Retail</option>
											</select>
										</div>
									</div>

									<div className="mb-4">
										<label htmlFor="formDescription" className="form-label">
											Description
										</label>
										<textarea
											id="formDescription"
											className="form-control custom-form-control"
											style={{ height: '100px'}}
											placeholder="Enter company description"
											value={description}
											onChange={(e) => setDescription(e.target.value)}
										/>
									</div>

									<div className="mb-4">
										<label htmlFor="formLogo" className="form-label">
											Company Logo
										</label>
										<input
											type="file"
											id="formLogo"
											accept=".jpg,.png,.jpeg"
											className="form-control form-radius"
											onChange={handleLogoChange}
										/>
									</div>

									<button type="submit" className="btn btn-primary mt-2 mb-3">
										Add Company
									</button>
								</form>
							</div>
						
						</div>
					</div>
					
				</div>
			</div>
		</Container>
	);
};

export default Create;
