import React, { useState, useEffect } from "react";
import './Chat.styles.css';
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import loadingGIF from '../../../assests/images/loading.gif';
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate, useParams } from "react-router-dom";
import { FiSend } from 'react-icons/fi';
import { RiLoader2Fill } from "react-icons/ri";
// react-icons
import { FaCopy } from "react-icons/fa6";
import { HiOutlineChat } from "react-icons/hi";
import { MdDeleteForever } from "react-icons/md";
import { MdHistory } from "react-icons/md";
import { FaBarsStaggered } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { MdOutlineAutoDelete } from "react-icons/md";

import { TbNewSection } from "react-icons/tb";

import Dropdown from "react-bootstrap/Dropdown";

import Select from 'react-select';
import AvtarImg from "../../../../src/assests/images/robot.png";
import box1Img from "../../../../../user/src/assests/images/create/background.jpg"
import box2Img from "../../../../../user/src/assests/images/create/background.jpg"
import box3Img from "../../../../../user/src/assests/images/create/background.jpg"
import AnimationSpinner from "../../../AnimationSpinner";
import { FaEllipsisVertical } from "react-icons/fa6";
import { TbFileExport } from "react-icons/tb";


const Chat = ({ base_url }) => {
	const navigate = useNavigate();
	const { conversationIds } = useParams();
	const [inputValue, setInputValue] = useState("");
	const [selectedStaffs, setSelectedStaffs] = useState([]);
	const [allStaffs, setAllStaffs] = useState([]);
	const [staffs, setStaffs] = useState([]);
	var user_avatar = base_url + "uploads/users/" + localStorage.getItem("avatar");
	var user_name = localStorage.getItem("username");
	const PROMPT_LIMIT = 300;
	const [messages, setMessages] = useState([]);
	const [lastReply, setLastReply] = useState('');
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState('');
	const [chatHistory, setChatHistory] = useState([]);
	const [chatId, setChatId] = useState(null);
	useEffect(() => {
		const fetchStaff = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				if (chatId == null || chatId == '') {
					setChatId(conversationIds + generateUniqueIdWithTimestamp());
				}
				const formData = new FormData();
				formData.append("action", "getAIStaffs");
				formData.append("token", token);
				formData.append("conversation_ids", conversationIds);
				const response = await axios.post(`${base_url}api/user/communication-and-collaboration`, formData);

				if (response.data.status) {
					if (response.data.staffs.length === 0) {
						Swal.fire({
							icon: "error",
							title: "Error",
							text: 'There are no hire AI staff found in selected project.',
						});
						return;
					}
					const staffOptions = response.data.staffs.map((staff) => ({
						value: staff.ids,
						label: staff.name,
					}));

					setStaffs(staffOptions || []);
					setAllStaffs(response.data.staffs);
					setTitle(response.data.conversation_title);
					setChatHistory(response.data.chat_history || []);
				} else {
					if (response.data.reset) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.response?.data?.message || error.message || "Something went wrong. Please try again.",
				});
			}
		};
		fetchStaff();
	}, [navigate, base_url, messages]);



	const generateUniqueIdWithTimestamp = () => {
		const timestamp = Date.now().toString(36);
		const randomPart = Math.random().toString(36).substr(2, 10);
		return (timestamp + randomPart).substr(0, 20);
	};

	const updateChatHistory = async () => {
		const token = GetToken();

		if (!token) {
			handleInvalidToken(navigate);
			return;
		}

		if (chatId == null || chatId == '') {
			return;
		}

		const jsonArray = JSON.stringify(messages);

		const jsonBlob = new Blob([jsonArray], { type: 'application/json' });
		// const chatHtml = document.getElementById("chat-messages").innerHTML;

		// const htmlBlob = new Blob([chatHtml], { type: "text/html" });
		const formData = new FormData();
		formData.append("token", token);
		formData.append("action", "updateChatHistory");
		formData.append("conversation_ids", conversationIds);
		formData.append("chatJson", jsonBlob);
		formData.append("heading", inputValue);
		formData.append("chatId", chatId);

		try {
			const response = await axios.post(`${base_url}api/user/communication-and-collaboration`, formData);

			if (response.data.status) {
			} else {
				if (response.data.reset) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error.response?.data?.message || error.message || "Something went wrong. Please try again.",
			});
		}
	};

	const handleSendMessage = async (e) => {
		e.preventDefault();
		const token = GetToken();

		if (!token) {
			handleInvalidToken(navigate);
			return;
		}

		if (inputValue.trim() === '') {
			Swal.fire("Please Enter Question!");
			return;
		}
		if (selectedStaffs.length === 0) {
			Swal.fire("Please Mention Staff!");
			return;
		}
		// if (inputValue.length < 5) {
		// 	Swal.fire("Input must be at least 5 characters long.");
		// 	return;
		// }

		// document.getElementById("chat-messages").style.display = "block";
		// document.getElementById("welcome_message").style.display = "none";
		const staffTags = selectedStaffs.map(staff => staff.label).join(", ");
		setMessages(prevMessages => [...prevMessages, { role: "user", content: inputValue, image: user_avatar, name: user_name, date: new Date().toLocaleString('en-US'), tags: staffTags }
		]);

		document.getElementById("send_icon").style.display = "none";
		document.getElementById("loader_2_fill").style.display = "block";
		const formData = new FormData();
		formData.append("token", token);
		formData.append("action", "conversation");
		formData.append("conversation_ids", conversationIds);
		formData.append("staffs", JSON.stringify(selectedStaffs));
		formData.append("last_reply", lastReply);
		formData.append("question", inputValue);
		formData.append("type", 'multiple');

		try {
			const response = await axios.post(`${base_url}api/user/communication-and-collaboration`, formData);



			if (response.data.status) {
				const aiMessages = response.data.data || [];
				aiMessages.forEach(aiMessage => {
					const item = allStaffs.find(item => item.ids === aiMessage.ids);
					const formattedText = aiMessage.message.replace(/\n/g, "<br />");
					setMessages(prevMessages => [...prevMessages, { role: "staff", content: formattedText, image: item.avatar, name: item.name, date: new Date().toLocaleString('en-US') }]);
					setLastReply(formattedText);

				});
				// console.log(messages);
				// setTimeout(() => {
				// 	updateChatHistory();
				// }, 1000);

				document.getElementById("send_icon").style.display = "block";
				document.getElementById("loader_2_fill").style.display = "none";
			} else {
				if (response.data.reset) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
				document.getElementById("send_icon").style.display = "block";
				document.getElementById("loader_2_fill").style.display = "none";
			}
		} catch (error) {
			document.getElementById("send_icon").style.display = "block";
			document.getElementById("loader_2_fill").style.display = "none";
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error.response?.data?.message || error.message || "Something went wrong. Please try again.",
			});
		}

		setInputValue("");
	};
	useEffect(() => {
		if (messages.length > 0) {
			updateChatHistory();
		}
	}, [messages]);

	const handleUserChange = (selectedOptions) => {
		setSelectedStaffs(selectedOptions);
	};


	const handleLiveChatDelete = (index) => {
		Swal.fire({
			title: "Are you sure you want to delete this message?",
			text: "This action cannot be undone.",
			showDenyButton: false,
			showCancelButton: true,
			confirmButtonText: "Delete",
		}).then((result) => {
			if (result.isConfirmed) {
				setMessages((prevMessages) => {
					return prevMessages.filter((msg, key) => key !== index);
				});
				updateChatHistory();
				Swal.fire("Deleted!", "The message has been deleted.", "success");
			} else if (result.isDenied) {
				Swal.fire("Action canceled", "The message was not deleted.", "info");
			}
		});
	};
	const handleLiveChatCopy = (text) => {
		window.navigator.clipboard.writeText(text)
			.then(() => {
				Swal.fire({
					title: "Copied!",
					text: "The message has been copied to your clipboard.",
					icon: "success",
					timer: 1500,
					showConfirmButton: false,
				});
			})
			.catch(() => {
				Swal.fire({
					title: "Copy Failed",
					text: "There was an issue copying the message. Please try again.",
					icon: "error",
					timer: 1500,
					showConfirmButton: false,
				});
			});
	};
	const handleNewChat = () => {
		setMessages([]);
		setInputValue("")
		// document.getElementById("chat-messages").setAttribute("role", conversationIds + generateUniqueIdWithTimestamp());
		setChatId(conversationIds + generateUniqueIdWithTimestamp());
		// document.getElementById("chat-messages").style.display = "none";
		// document.getElementById("welcome_message").style.display = "block";
	};
	const handleGetOldChat = (key) => {
		const history = chatHistory.find((history, index) => index === key);
		if (history && history.chat_ids !== chat_id) {
			var chat_id = history.chat_ids;
			var chat_file_url = history.chat_file_url;

			fetchData(chat_file_url)
				.then(data => {
					if (data) {
						setChatId(chat_id);
						setMessages(data);
					} else {
						Swal.fire("Somethings Went's Wrong!");
					}
				})
				.catch(error => {
					console.error("Error fetching data:", error);
					Swal.fire("Somethings Went's Wrong!");

				});
		}
	};
	const fetchData = async (url) => {
		try {
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`Error: ${response.statusText}`);
			}
			const data = await response.json();
			return data;
		} catch (error) {
			console.error("Failed to fetch data:", error);
			return null;
		}
	};

	const exportConversation = () => {
		if (!messages || messages.length === 0) {
			alert("No messages to export.");
			Swal.fire("No conversation to export!");

			return;
		}

		const conversation = messages
			.map(
				(msg) =>
					`[${msg.date}] ${msg.name} (${msg.role}):\n${msg.content}\n`
			)
			.join("\n");

		const blob = new Blob([conversation], { type: "text/plain" });
		const url = URL.createObjectURL(blob);

		const link = document.createElement("a");
		link.href = url;
		link.download = title + ".txt";
		link.click();
		URL.revokeObjectURL(url);
	};



	const handleDeleteChat = async (ids) => {
		Swal.fire({
			title: "Are you sure you want to delete this conversation?",
			text: "This action cannot be undone.",
			showDenyButton: false,
			showCancelButton: true,
			confirmButtonText: "Delete",
		}).then(async (result) => {
			if (result.isConfirmed) {
				const token = GetToken();

				if (!token) {
					handleInvalidToken(navigate);
					return;
				}

				const formData = new FormData();
				formData.append("token", token);
				formData.append("action", "deleteChatConversation");
				formData.append("chat_ids", ids);
				formData.append("conversations_ids", conversationIds);

				try {
					const response = await axios.post(`${base_url}api/user/communication-and-collaboration`, formData);

					if (response.data.status) {
						if (chatId === ids) {
							handleNewChat();
						}
						setChatHistory(response.data.chat_history || []);
					} else {
						if (response.data.reset) {
							handleInvalidToken(navigate);
							return;
						}
						Swal.fire({
							icon: "error",
							title: "Error",
							text: response.data.message,
						});
					}
				} catch (error) {
					Swal.fire({
						icon: "error",
						title: "Error",
						text: error.response?.data?.message || error.message || "Something went wrong. Please try again.",
					});
				}
			} else if (result.isDenied) {
				Swal.fire("Action canceled", "The conversation was not deleted.", "info");
			}
		});
	};



	return (
		<Container className="h-100">
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12 mx-auto">
						<div className="chat-container">
							<div className="heading">
								<h6 className="project-name">{title}</h6>

								<div className="right-div d-flex justify-content-between align-items-center">

									{/* chat button */}

									<button className="new-chat me-2" title="New Chat" onClick={handleNewChat}><TbNewSection /></button>
									<button className="new-chat me-2" title="Export Conversation" onClick={exportConversation}><TbFileExport /></button>

									<div className="dropdown button2" >
										<button className="dropdown-button" title="Chat History"><MdHistory />
										</button>

										<div id="inner-dropdown-content" className="inner-dropdown-content">

											{chatHistory.length > 0 ? (
												chatHistory.map((history, index) => (
													<div className="chat-addition me-2" key={index} >
														<p className="inner-content" >{history.title}</p>
														<Dropdown className="chat-dropdown">
															<Dropdown.Toggle id="dropdown-no-caret" as="button" className="btn btn-sm dropdown-toggle">
																<FaEllipsisVertical />
															</Dropdown.Toggle>
															<Dropdown.Menu>
																<Dropdown.Item onClick={() => handleGetOldChat(index)}>
																	<FiEdit className="f17 text-success" />
																	View
																</Dropdown.Item>
																<Dropdown.Item onClick={() => handleDeleteChat(history.chat_ids)}>
																	<MdDeleteOutline className="f17 text-danger" />
																	Delete
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</div>
												))
											) : (
												<p className="text-center mt-3 fw-800">
													<MdOutlineAutoDelete className="me-2 f25"/>
													No chat history available.
												</p>
											)}
										</div>
									</div>
								</div>
							</div>

							<div className="chat-box" id="chat-messages" role={chatId} style={{ display: messages.length === 0 ? 'none' : 'block' }}>
								{messages.map((msg, index) => (
									<div key={index} className={msg.role === "user" ? "user-message" : "staff-message"}>
										<div className="d-flex align-items-center justify-content-between mb-1 px-1 flex-wrap">
											<span className="user-name">{msg.date}</span>
											<div className="">
												<span className="user-name">{msg.name}</span>
												<img className="img-sm" src={msg.image} alt="img" />
											</div>
										</div>
										<p
											dangerouslySetInnerHTML={{
												__html: msg.content
											}}
										/>
										<div className="d-flex justify-content-between align-items-center flex-wrap">
											{msg.role === "user" && (
												<div className="tag-block">
													<span>Tags</span>
													<small className="tags-text">
														{msg.tags && msg.tags.split(", ").map((tag, i) => (
															<span key={i}>@ {tag}{i < msg.tags.split(", ").length - 1 ? ', ' : ''}</span>
														))}
													</small>
												</div>
											)}
											<div className="chat-icon-div d-flex">
												<FaCopy onClick={() => handleLiveChatCopy(msg.content)} className="icon-fill copy-btn pointer" />
												<MdDeleteForever className="icon-fill delete-btn pointer" onClick={() => handleLiveChatDelete(index)} />
											</div>
										</div>

									</div>
								))}
							</div>

							<div className="chat-front-view my-4" id="welcome_message" style={{ display: messages.length === 0 ? 'block' : 'none' }}>								<div className="heading-area text-center">
								<h5 className="main-title">What can I help with?</h5>
								<h6 className="sub-text">How can I assist you today?</h6>									<small className="tags-text">
									{allStaffs && allStaffs.map((staff, i) => (
										<span key={i}>@{staff.name}{'(' + staff.field + ')'}{i < staffs.length - 1 ? ', ' : ''}</span>
									))}
								</small>
							</div>
							</div>

							<div className="input-container">

								<Select
									isMulti
									menuPlacement="top"
									value={selectedStaffs}
									onChange={handleUserChange}
									options={staffs}
									// className="multi-select form-control custom-form-control"
									classNamePrefix="custom"
									placeholder="Mention staff..."
								/>
								<input
									type="text"
									placeholder="Type new questions"
									value={inputValue}
									maxLength={PROMPT_LIMIT}
									onChange={(e) => setInputValue(e.target.value)}
								/>
								<small className="instuction-text">{inputValue.length}/{PROMPT_LIMIT} characters</small>

								<FiSend className="send-icon" id="send_icon" onClick={handleSendMessage} />
								<img src={loadingGIF} className="loader-2" id="loader_2_fill" style={{ display: 'none' }} alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Chat;


