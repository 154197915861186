import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { handleInvalidToken } from "../../AuthUtils";
import AnimationSpinner from "../../AnimationSpinner";
import GetToken from "../../GetToken";
import UpGradeBox from "../../components/upgradeBox/UpGradeBox";

const UpGradePage = ({ base_url }) => {
	const [userPackages, setUserPackages] = useState([]);
	const [packages, setPackages] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchUserPackage = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken();
					return;
				}
				setLoading(true);
				const formdata = new FormData();
				formdata.append("action", "fetchUserPackages");
				formdata.append("token", token);
				const response = await axios.post(
					`${base_url}api/user/get-user-resource`,
					formdata
				);
				if (response.data.status) {
					setLoading(false);
					setUserPackages(response.data.items);
				} else {
					setLoading(false);
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Failed to insert data. Please try again.",
				});
			}
		};

		// const fetchPackageList = async () => {
		// 	try {
		// 		const token = GetToken();
		// 		if (!token) {
		// 			handleInvalidToken();
		// 			return;
		// 		}
		// 		setLoading(true);
		// 		const formdata = new FormData();
		// 		formdata.append("action", "fetchPackages");
		// 		formdata.append("token", token);
		// 		const response = await axios.post(
		// 			`${base_url}api/user/get-user-resource`,
		// 			formdata
		// 		);
		// 		if (response.data.status) {
		// 			setLoading(false);
		// 			setPackages(response.data.items);
		// 		} else {
		// 			setLoading(false);
		// 			Swal.fire({
		// 				icon: "error",
		// 				title: "Error",
		// 				text: response.data.message,
		// 			});
		// 		}
		// 	} catch (error) {
		// 		setLoading(false);
		// 		Swal.fire({
		// 			icon: "error",
		// 			title: "Error",
		// 			text:
		// 				error.response?.data?.message ||
		// 				error.message ||
		// 				"Failed to insert data. Please try again.",
		// 		});
		// 	}
		// };
		// fetchPackageList();
		fetchUserPackage();
	}, []);

	return (
		<div className="right-container">
			<AnimationSpinner show={loading} />
			<div className="content">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="page-title-box">
								<h4 className="page-title">Upgrade</h4>
								<div className="row">
								{userPackages.includes(1) && userPackages.includes(2) ? (
									<UpGradeBox title={"Team Buddy AI Front End"} activated={true}/>
								) : <UpGradeBox title={"Team Buddy AI Front End"} activated={false} url="https://www.google.com/"/>}

								{userPackages.includes(3) ? (
									<UpGradeBox title={"Team Buddy AI Premium Edition"} activated={true}/>
								) : <UpGradeBox title={"Team Buddy AI Premium Edition"} activated={false} url="https://www.google.com/"/>}
								{userPackages.includes(4) ? (
									<UpGradeBox title={"Team Buddy AI Max Edition"} activated={true}/>
								) : <UpGradeBox title={"Team Buddy AI Max Edition"} activated={false} url="https://www.google.com/"/>}
								{userPackages.includes(5) ? (
									<UpGradeBox title={"Team Buddy AI Website Edition"} activated={true}/>
								) : <UpGradeBox title={"Team Buddy AI Website Edition"} activated={false} url="https://www.google.com/"/>}
								{userPackages.includes(6) ? (
									<UpGradeBox title={"Team Buddy AI DFY Edition"} activated={true}/>
								) : <UpGradeBox title={"Team Buddy AI DFY Edition"} activated={false} url="https://www.google.com/"/>}
								{userPackages.includes(7) ? (
									<UpGradeBox title={"Team Buddy AI Traffic Edition"} activated={true}/>
								) : <UpGradeBox title={"Team Buddy AI Traffic Edition"} activated={false} url="https://www.google.com/"/>}
								{userPackages.includes(8) && userPackages.includes(9) ? (
									<UpGradeBox title={"Team Buddy AI Agency Edition"} activated={true}/>
								) : <UpGradeBox title={"Team Buddy AI Agency Edition"} activated={false} url="https://www.google.com/"/>}
								{userPackages.includes(10) ? (
									<UpGradeBox title={"Team Buddy AI Appointment Edition"} activated={true}/>
								) : <UpGradeBox title={"Team Buddy AI Appointment Edition"} activated={false} url="https://www.google.com/"/>}
								{userPackages.includes(11) && userPackages.includes(12) ? (
									<UpGradeBox title={"Team Buddy AI Reseller Edition"} activated={true}/>
								) : <UpGradeBox title={"Team Buddy AI Reseller Edition"} activated={false} url="https://www.google.com/"/>}
								{userPackages.includes(13) ? (
									<UpGradeBox title={"Team Buddy AI Whitelabel Edition"} activated={true}/>
								) : <UpGradeBox title={"Team Buddy AI Whitelabel Edition"} activated={false} url="https://www.google.com/"/>}
								{userPackages.includes(14) ? (
									<UpGradeBox title={"Team Buddy AI VIP Pack"} activated={true}/>
								) :null}
								{userPackages.includes(15) ? (
									<UpGradeBox title={"Team Buddy AI Automation Pack"} activated={true}/>
								) :null}
								{userPackages.includes(16) ? (
									<UpGradeBox title={"Team Buddy AI Unlimited Pack"} activated={true}/>
								) :null}
								{userPackages.includes(17) ? (
									<UpGradeBox title={"Team Buddy AI Website Template Pack"} activated={true}/>
								) :null}
								{userPackages.includes(18) ? (
									<UpGradeBox title={"Team Buddy AI DFY Pack"} activated={true}/>
								) :null}
								{userPackages.includes(19) ? (
									<UpGradeBox title={"Team Buddy AI Traffic Unlimited Pack"} activated={true}/>
								) :null}
								{userPackages.includes(20) ? (
									<UpGradeBox title={"Team Buddy AI Agency Club"} activated={true}/>
								) :null}
								{userPackages.includes(21) ? (
									<UpGradeBox title={"Team Buddy AI Appointment Unlimited Pack"} activated={true}/>
								) :null}
								{userPackages.includes(22) ? (
									<UpGradeBox title={"Team Buddy AI Reseller Bundle"} activated={true}/>
								) :null}
								{userPackages.includes(23) ? (
									<UpGradeBox title={"Team Buddy AI VIP Developer Pack"} activated={true}/>
								) :null}
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UpGradePage;
