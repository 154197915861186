import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate, useParams } from "react-router-dom";
import AnimationSpinner from "../../../AnimationSpinner";

const Edit = (props) => {
	const navigate = useNavigate();
	const { agentIds } = useParams();
	const [agentName, setAgentName] = useState("");
	const [description, setDescription] = useState("");
	const [gender, setGender] = useState("");
	const [avatar, setAvatar] = useState(null);
	const [oldAvatar, setOldAvatar] = useState(null);
	const [field, setField] = useState("");
	const [prompt, setPrompt] = useState("");
	const [loading, setLoading] = useState(false);

	const AGENT_NAME_LIMIT = 50;
	const DESCRIPTION_LIMIT = 300;
	const PROMPT_LIMIT = 300;
	const Field = 50;

	useEffect(() => {
		const token = GetToken();
		if (!token) {
			handleInvalidToken(navigate);
			return;
		}
		setLoading(true)
		const formData = new FormData();
		formData.append("agentIds", agentIds);
		formData.append("token", token);
		formData.append("action", "getEditCustomAIAgent");

		axios.post(`${props.base_url}user/api/process-resource`, formData)
			.then((response) => {
				setLoading(false)
				const data = response.data.items;
				setAgentName(data.name);
				setDescription(data.description);
				setGender(data.gender);
				setField(data.field);
				setPrompt(data.prompt);
				setOldAvatar(data.avatar);
			})
			.catch((error) => {
				setLoading(false)
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.message || "Failed to load agent data.",
				});
			});
	}, [agentIds, props.base_url]);

	const handleAvatarChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			setAvatar(file);
		}
	};

	function Sweetalert(field) {
		Swal.fire({
			icon: "error",
			title: "Validation Error",
			text: field + " is required!",
		});
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		const token = GetToken();

		if (!token) {
			handleInvalidToken(navigate);
			return;
		}

		let isValid = true;
		if (!agentName && !description && !prompt && !gender && !field) {
			Sweetalert("Agent Name, Description, Instruction, Gender & Field");
			isValid = false;
		} else {
			if (!agentName) {
				Sweetalert("Agent Name");
				isValid = false;
			}
			if (!description) {
				Sweetalert("Description");
				isValid = false;
			}
			if (!prompt) {
				Sweetalert("Instruction");
				isValid = false;
			}
			if (!gender) {
				Sweetalert("Gender");
				isValid = false;
			}
			if (!field) {
				Sweetalert("Field");
				isValid = false;
			}

		}
		if (!isValid) {
			return;
		}
		setLoading(true)
		const formData = new FormData();
		formData.append("agent_name", agentName);
		formData.append("description", description);
		formData.append("prompt", prompt);
		formData.append("gender", gender);
		formData.append("field", field);
		formData.append("avatar", avatar);
		formData.append("token", token);
		formData.append("action", "editCustomAIAgent");
		formData.append("agentIds", agentIds);

		axios.post(`${props.base_url}user/api/process-resource`, formData)
			.then((response) => {
				if (response.data.status === true) {
					setLoading(false)
					Swal.fire({
						icon: "success",
						title: "AI Agent Updated",
						text: "Your custom AI agent has been updated successfully.",
					});
					navigate("/manage-agents", { replace: true });
				} else {
					setLoading(false)
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			})
			.catch((error) => {
				setLoading(false)
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			});
	};

	return (
		<Container className="h-100">
			<AnimationSpinner show={loading}/>
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card">
							<div className="form-body">
								<div className="head-part">
									<h4 className="title">Edit AI Agent Details</h4>
								</div>

								<form onSubmit={handleSubmit}>
									<div className="mb-4">
										<label htmlFor="formAgentName" className="form-label">
											Agent Name
										</label>
										<input
											type="text"
											id="formAgentName"
											className="form-control custom-form-control"
											placeholder="Enter agent name"
											value={agentName}
											onChange={(e) => setAgentName(e.target.value)}
											maxLength={AGENT_NAME_LIMIT}
										/>
										<small>{agentName.length}/{AGENT_NAME_LIMIT} characters</small>
									</div>

									<div className="mb-4">
										<label htmlFor="formDescription" className="form-label">
											Description
										</label>
										<textarea
											id="formDescription"
											className="form-control custom-form-control"
											style={{ height: '100px' }}
											placeholder="Enter agent description"
											value={description}
											onChange={(e) => setDescription(e.target.value)}
											maxLength={DESCRIPTION_LIMIT}
										/>
										<small>{description.length}/{DESCRIPTION_LIMIT} characters</small>
									</div>

									<div className="mb-4">
										<label htmlFor="formPrompt" className="form-label">
											Instruction
										</label>
										<textarea
											id="formPrompt"
											className="form-control custom-form-control"
											style={{ height: '100px' }}
											placeholder="Enter instruction for AI agent"
											value={prompt}
											onChange={(e) => setPrompt(e.target.value)}
											maxLength={PROMPT_LIMIT}
										/>
										<small>{prompt.length}/{PROMPT_LIMIT} characters</small>
									</div>

									<div className="mb-4">
										<label htmlFor="formGender" className="form-label">
											Gender
										</label>
										<select
											id="formGender"
											className="form-select custom-form-select"
											value={gender}
											onChange={(e) => setGender(e.target.value)}
										>
											<option value="">Select gender</option>
											<option value="Male">Male</option>
											<option value="Female">Female</option>
										</select>
									</div>

									<div className="mb-4">
										<label htmlFor="formField" className="form-label">
											Field
										</label>
										<input
											type="text"
											id="formField"
											className="form-control custom-form-control"
											placeholder="Enter field of expertise"
											value={field}
											onChange={(e) => setField(e.target.value)}
											maxLength={Field}
										/>
										<small>{field.length}/{Field} characters</small>
									</div>

									<div className="mb-4">
										<label htmlFor="formAvatar" className="form-label">
											Avatar
										</label>
										<input
											type="file"
											id="formAvatar"
											accept=".jpg,.png,.jpeg"
											className="form-control custom-form-control"
											onChange={handleAvatarChange}
										/>
										<img
											src={oldAvatar}
											alt="Avatar"
											className="img-fluid img-box mb-3 "
										/>
									</div>

									<button type="submit" className="btn btn-primary mt-3">
										Update AI Agent
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Edit;
