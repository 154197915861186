import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { handleInvalidToken } from "../../AuthUtils";
import AnimationSpinner from "../../AnimationSpinner";
import axios from "axios";
import GetToken from "../../GetToken";

// react-icons
import { FaCheck } from "react-icons/fa";


const OpenAi = ({ base_url }) => {
	const [builtInValue, setBuiltInValue] = useState("1");
	const [fetcheddata, setFecthedData] = useState({});
	const [apiValue, setApiValue] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}

				setLoading(true);
				const formData = new FormData();
				formData.append("action", "fetchApiData");
				formData.append("token", token);

				const response = await axios.post(
					`${base_url}api/user/get-user-resource`,
					formData
				);

				if (response && response.data && response.data.status) {
					setLoading(false);
					setFecthedData(response.data);
					setBuiltInValue(response.data.built_in);
					setApiValue(response.data.chatgpt_api);
				
				} else {
					setLoading(false);
				}
			} catch (error) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Failed to insert data. Please try again.",
				});
			}
		};
		fetchData();
	}, []);

	const updateBuiltIn = async () => {
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			
		
			const formData = new FormData();
			formData.append("action", "apiAction");
			formData.append("token", token);
			formData.append("built_in", builtInValue);
			formData.append("chatgpt_api", apiValue === "" ? "" : apiValue);
			const response = await axios.post(
				`${base_url}api/user/get-user-resource`,
				formData
			);
			if (response.data.status) {		
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Failed to insert data. Please try again.",
			});
		}
	};

	const updateApiKey = async () => {
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
	
			setLoading(true);
			const formData = new FormData();
			formData.append("action", "apiAction");
			formData.append("token", token);
			formData.append("built_in", builtInValue);
			formData.append("chatgpt_api", apiValue === "" ? "" : apiValue);

			const response = await axios.post(
				`${base_url}api/user/get-user-resource`,
				formData
			);
			if (response.data.status) {
				setLoading(false);
				if (apiValue != "") {
					Swal.fire({
						icon: "success",
						title: "Data Updated",
						text: response.data.message,
					});
				}
			} else {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Failed to insert data. Please try again.",
			});
		}
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		updateApiKey();
	};
	useEffect(() => {
		const timeout = setTimeout(() => {
			updateBuiltIn();
		}, 300); 
	
		return () => clearTimeout(timeout); 
	}, [builtInValue]);
	return (
		<div className="container h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12 col-md-12 mx-auto">
						<div className="card mb-4  Setting-box">
							<div className="card-header">
								<h3 className="api-title">API AI Setting</h3>
							</div>
							<div className="card-body">
								<form>
									<div className="row">
										<div className="col-md-12 mb-2">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input"
													type="radio"
													name="built_in"
													id="inlineRadio11"
													value="1"
													checked={builtInValue == 1}
													onChange={(e) => setBuiltInValue(e.target.value)}
													// onClick={updateBuiltIn}
												/>
												<label
													className="form-check-label"
													htmlFor="inlineRadio11"
												>
													Built-in API Key
												</label>
											</div>
										</div>
										<div className="col-md-12 mb-4">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input"
													type="radio"
													name="built_in"
													id="inlineRadio22"
													value="0"
													checked={builtInValue == "0"}
													onChange={(e) => setBuiltInValue(e.target.value)}
													// onClick={updateBuiltIn}
												/>
												<label
													className="form-check-label"
													htmlFor="inlineRadio22"
												>
													Add On Your Own API Key
												</label>
											</div>
										</div>
									</div>
									<div className="voxuploadbox3">
										<h6 className="note-text">
											{builtInValue == 1
												? "You Are Using Our Built-In API Key"
												: "You Are Using Your Own API Key"}
											<FaCheck style={{ color: "#169B6B" }} />
										</h6>
									</div>
								</form>
							</div>
						</div>

						{builtInValue == 0 ? (
							<div className="localuploadbox3">
								{/* Open AI API Key */}
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-12">
												<div className="mb-4">
													<div className="border-bottom-light  mb-3">
														<div className="row  mb-1 align-items-end">
															<div className="col-lg-12 mx-auto mb-3">
																<label>
																	<span className="text-danger">*</span>Open AI
																	API Key
																</label>
																<input
																	className="form-control"
																	type="password"
																	name="chatgpt_api"
																	value={apiValue || ""}
																	autoComplete="new-password"
																	onChange={(e) => setApiValue(e.target.value)}
																/>
															</div>
															<div className="col-lg-12">
																<div className="mb-3">
																	<div
																		className="btn btn-primary"
																		onClick={handleFormSubmit}
																	>
																		Submit
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="mb-2">
													<div className="card-header px-2">
														<h3 className="title mb-3 border-none">
															Steps - 1 For Open AI API Key
														</h3>
													</div>
													<div className="">
														<div className="row">
															<div className="col-lg-6">
																<div className="card mb-3">
																	<div className="card-header">
																		<h3 className="open-ai-step">Step 1</h3>
																	</div>
																	<div className="card-body pt-2">
																		<div className="card-box-inner">
																			<h3 className="s-title">
																				Signup for API key
																			</h3>
																			<h6>
																				<Link
																					target="_blank"
																					className="text-primary font-weight-bolder"
																					to="https://auth0.openai.com/u/signup/identifier?state=hKFo2SBCanlYdUhfbmRGRFdBdHQ4V0xrRXBCNHc2RHROOWV3WaFur3VuaXZlcnNhbC1sb2dpbqN0aWTZIFpTZzBZUzd3LVhmWHpCUF9LdXhkQkxEbWhfelh2Y3dxo2NpZNkgRFJpdnNubTJNdTQyVDNLT3BxZHR3QjNOWXZpSFl6d0Q"
																				>
																					https://auth0.openai.com/u/signup
																				</Link>
																			</h6>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-lg-6">
																<div className="card mb-3">
																	<div className="card-header">
																		<h3 className="open-ai-step">Step 2</h3>
																	</div>
																	<div className="card-body pt-2">
																		<div className="card-box-inner">
																			<h3 className="s-title">
																				Get your API key
																			</h3>
																			<h6>
																				<Link
																					target="_blank"
																					className="text-primary font-weight-bolder"
																					to="https://platform.openai.com/account/api-keys"
																				>
																					https://platform.openai.com/account/api-keys
																				</Link>
																			</h6>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											{/* <div className="col-lg-12">
												<div className="border-bottom-light mb-4">
													<div className="card-header px-2">
														<h3 className="title mb-3 border-none">
															Steps - 2 For Diffusion API Key
														</h3>
													</div>
													<div className="">
														<div className="row">
															<div className="col-lg-6">
																<div className="card mb-5">
																	<div className="card-header">
																		<h3 className="open-ai-step">Step 1</h3>
																	</div>
																	<div className="card-body pt-2">
																		<div className="card-box-inner">
																			<h3 className="s-title">
																				Signup for API key
																			</h3>
																			<h6>
																				<Link
																					target="_blank"
																					className="text-primary font-weight-bolder"
																					to="https://stablediffusionapi.com/register"
																				>
																					https://stablediffusionapi.com/register
																				</Link>
																			</h6>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-lg-6">
																<div className="card mb-5">
																	<div className="card-header">
																		<h3 className="open-ai-step">Step 2</h3>
																	</div>
																	<div className="card-body pt-2">
																		<div className="card-box-inner">
																			<h3 className="s-title">Get your API key</h3>
																			<h6>
																				<Link
																					target="_blank"
																					className="text-primary font-weight-bolder"
																					to="https://stablediffusionapi.com/settings/api"
																				>
																					https://stablediffusionapi.com/settings/api
																				</Link>
																			</h6>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div> */}
											<div className="col-lg-12">
												<div className="mb-3">
													<div className="card-header px-2 pt-0 mb-1">
														<h3 className="title mb-3 border-none">
															Watch The Reference Video
														</h3>
													</div>

													<div className="row">
														<div className="col-lg-12">
															<div className="card mb-3">
																<div className="card-body">
																	<div className="card-box-video p-0">
																		<img width="100%" src="" alt="logo" />
																	</div>
																</div>
															</div>

															{/* <div className="col-lg-6">
																<div className="card mb-3">
																	<div className="card-body">
																		<div className="card-box-video p-0">
																			<img width="100%" src="" alt="logo" />
																		</div>
																	</div>
																</div>
															</div> */}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default OpenAi;
