import React, { useState, useEffect } from "react";

import {
	Card,
	Button,
	Container,
	Row,
	Col,
	Pagination,
	Form,
} from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../../AnimationSpinner";

// react-icons
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { CiSearch } from "react-icons/ci";

const List = ({ base_url }) => {
	const [items, setItems] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(6);
	const [totalItems, setTotalItems] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true)
				const formData = new FormData();
				formData.append("action", "getCompanyLists");
				formData.append("token", token);
				formData.append("offset", currentPage);
				formData.append("limit", itemsPerPage);
				formData.append("search", searchTerm);
				const response = await axios.post(
					`${base_url}user/api/process-resource`,
					formData
				);

				if (response.data.status === true) {
					setLoading(false)
					setItems(response.data.items || []);
					setTotalItems(response.data.totalItems || 0);
				} else {
					setLoading(false)
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false)
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			}
		};

		fetchData();
	}, [currentPage, navigate, base_url, searchTerm, itemsPerPage]);

	const handleEdit = (ids) => {
		navigate(`/edit-company/${ids}`);
	};

	const handleDelete = async (ids) => {
		const result = await Swal.fire({
			title: "Are you sure?",
			text: "Deleting this company will permanently remove all associated staff, departments, and projects. You will not be able to recover this item!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, delete it!",
		});

		if (result.isConfirmed) {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true)
				const formData = new FormData();
				formData.append("action", "removeCompany");
				formData.append("token", token);
				formData.append("company_ids", ids);
				const response = await axios.post(
					`${base_url}user/api/process-resource`,
					formData
				);

				if (response.data.status === true) {
					setLoading(false)
					setItems(items.filter((item) => item.ids !== ids));
					Swal.fire("Deleted!", "Your item has been deleted.", "success");
				} else {
					setLoading(false)
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false)
				// console.error("Error deleting item:", error);
				Swal.fire("Error", "Failed to delete item", "error");
			}
		}
	};

	const totalPages = Math.ceil(totalItems / itemsPerPage);


	return (
		<div className="right-container">
			<AnimationSpinner show={loading} />
			<div className="row">
				<div className="col-lg-12">
					<div className="form-card">
						<div className="form-body">
						<Form.Group controlId="search" className="company-search-bar">
								
							<div className="head-part d-flex justify-content-between w-100 align-items-center">
								<h4 className=" title"> Manage your company</h4>
									<Form.Control className="custom-form-control w-50 mb-2"
										type="text"
										placeholder="Search for a company..."
										value={searchTerm}
										onChange={(e) => {
											setSearchTerm(e.target.value);
											setCurrentPage(1);
										}}
									/>
							</div>
						</Form.Group>
								<Row>
									{items.map((item) => (
										<Col xl={4}  key={item.ids} className="col-lg-6 col-md-6 mb-4">
											<div className="company-card">
												<div className="company-card-image-container">
													<Card.Img variant="top" src={item.company_logo} />
												</div>
												<Card.Body className="px-3 pt-2 pb-3">
													<Card.Title>{item.company_name}</Card.Title>
													<Card.Text className="comapny-category">
														{item.category}
													</Card.Text>
													{/* <Card.Text className="company-description truncated-two-lines">
														{item.description}
													</Card.Text> */}
													<Card.Text className="company-creation-date">
														<span>Created :</span>{" "}
														{item.created}
													</Card.Text>
													<div className="compay-card-btn-container mt-3 mb-1 w-100 d-flex justify-content-start align-items-center gap-2">
														<Button
															variant="primary"
															onClick={() => handleEdit(item.ids)}
														>
															<FaRegEdit /> Edit
														</Button>
														<Button
															variant="danger"
															onClick={() => handleDelete(item.ids)}
															className="ml-2"
														>
															<MdDeleteForever />
															Delete
														</Button>
													</div>
												</Card.Body>
											</div>
										</Col>
									))}
								</Row>

							<div className="pagination-container w-full d-flex justify-content-end align-items-center border-top pt-2 mt-4">
								<Pagination className="mb-0">
									<Pagination.Prev
										onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
										disabled={currentPage === 1}
									/>
									{[...Array(totalPages)].map((_, index) => (
										<Pagination.Item
											key={index + 1}
											active={index + 1 === currentPage}
											onClick={() => setCurrentPage(index + 1)}
										>
											{index + 1}
										</Pagination.Item>
									))}
									<Pagination.Next
										onClick={() =>
											setCurrentPage((prev) => Math.min(prev + 1, totalPages))
										}
										disabled={currentPage === totalPages}
									/>
								</Pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	);
};

export default List;
