import React from 'react';
import animation_gif from '../src/assests/images/wait.gif';

const AnimationSpinner = ({ show }) => {
	return (
		<div id='animation-bar-spinner' style={{ display: show ? 'block' : 'none' }}>
			<div className="animation-spinner">
				<img src={animation_gif} alt="Loading..." />
			</div>
		</div>
	);
};

export default AnimationSpinner;
