import React from "react";
import "./UpGradeBox.css";
import { Link } from "react-router-dom";
const UpGradeBox = ({ title, activated, url }) => {
	return (
		<div className="col-lg-4 col-md-6">
			<div className="upgrade-box">
				<div className="media">
					<img
						src="http://localhost/aistaff/uploads/oto/live.png"
						width="100%"
						alt=""
					/>
				</div>
				<div className="content">
					<h5 className="title">{title}</h5>
					<div className="download align-self-center text-center">
						{activated ? (
							<button className="btn btn-success">Activated</button>
						) : (
							<a href={url} target="_blank" rel="noopener noreferrer">
								<button className="btn btn-danger">Click to Activate</button>
							</a>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default UpGradeBox;
