import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import AnimationSpinner from "../../../AnimationSpinner";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate } from "react-router-dom";

const AgencyCreate = ({ base_url }) => {
	const [agency, setAgency] = useState({
		agencyName: "",
		email: "",
		password: "",
		confirmPassword: "",
	});
	const [isSubmitted, setIsSubmitted] = useState(false);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	function Sweetalert(field) {
		Swal.fire({
			icon: "error",
			title: "Validation Error",
			text: field + " is required!",
		});
	}

	// function to create new agency
	const handleSumit = (event) => {
		event.preventDefault();
		if (isSubmitted) return;
		setIsSubmitted(true);

		try {
			var token = GetToken();

			if (!token) {
				handleInvalidToken(navigate);
				return;
			}

			let isValid = true;
			if (
				!agency.agencyName &&
				!agency.email &&
				!agency.password &&
				!agency.confirmPassword
			) {
				setIsSubmitted(false);
				Sweetalert("Agency Name, Email & Password");
				isValid = false;
			} else {
				setIsSubmitted(false);
				if (!agency.agencyName) {
					Sweetalert("Agency Name");
					isValid = false;
				}
				if (!agency.email) {
					Sweetalert("Email");
					isValid = false;
				}
				if (!agency.password) {
					Sweetalert("Password");
					isValid = false;
				}
				if (!agency.confirmPassword) {
					Sweetalert("Confirm Password");
					isValid = false;
				}
				if (agency.password !== agency.confirmPassword) {
					Sweetalert("Password & Confirm Password not match");
					isValid = false;
				}
			}
			if (!isValid) {
				return;
			}
			setLoading(true);
			const formData = new FormData();
			formData.append("token", token);
			formData.append("action", "createAgency");

			formData.append("agency_name", agency.agencyName);
			formData.append("agency_email", agency.email);
			formData.append("password", agency.password);

			axios
				.post(`${base_url}api/user/get-agency-resource`, formData)
				.then((response) => {
					if (response.data.status === true) {
						setLoading(false);
						Swal.fire({
							icon: "success",
							title: "Agency Added",
							text: "Your Agency details have been added successfully.",
						});
						navigate("/manage-agency", { replace: true });
						setIsSubmitted(false);
					} else {
						setLoading(false);
						setIsSubmitted(false);
						if (response.data.reset === true) {
							handleInvalidToken(navigate);
							return;
						}
						Swal.fire({
							icon: "error",
							title: "Error",
							text: response.data.message,
						});
					}
				});
		} catch (error) {
			setLoading(false);
			setIsSubmitted(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	return (
		<div className="container h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12 col-md-10 mx-auto">
						<div className="form-card">
							<div className="form-body">
								<div className="head-part">
									<h6 className="title">New agency</h6>
								</div>
								<form
									onSubmit={handleSumit}
									acceptCharset="utf-8"
									autoComplete="off"
								>
									<div className="card-body">
										<input
											type="hidden"
											id="base_url"
											name="base_url"
											value=""
										/>
										<div className="row form-group mb-0">
											<div className="col-md-6 mb-3">
												<label className="form-label">
													Agency Name <span className="text-danger">*</span>
												</label>
												<input
													type="text"
													name="first_name"
													value={agency.agencyName}
													onChange={(e) =>
														setAgency({ ...agency, agencyName: e.target.value })
													}
													id="first_name"
													className="form-control custom-form-control"
												/>
											</div>

											<div className="col-md-6 mb-3">
												<label className="form-label">
													Email Address <span className="text-danger">*</span>
												</label>
												<input
													type="text"
													name="email_address"
													value={agency.email}
													onChange={(e) =>
														setAgency({ ...agency, email: e.target.value })
													}
													id="email_address"
													className="form-control custom-form-control"
													autoComplete="new-password"
												/>
											</div>

											<div className="col-md-6 mb-3">
												<label className="form-label">
													Password <span className="text-danger">*</span>
												</label>
												<input
													type="password"
													name="password"
													value={agency.password}
													onChange={(e) =>
														setAgency({ ...agency, password: e.target.value })
													}
													id="password"
													className="form-control custom-form-control"
													autoComplete="new-password"
												/>
											</div>
											<div className="col-md-6 mb-3">
												<label className="form-label">
													Confirm Password{" "}
													<span className="text-danger">*</span>{" "}
												</label>
												<input
													type="password"
													name="confirm_password"
													value={agency.confirmPassword}
													onChange={(e) =>
														setAgency({
															...agency,
															confirmPassword: e.target.value,
														})
													}
													id="confirm_password"
													className="form-control custom-form-control"
												/>
											</div>
											{/* <div className="col-lg-4 col-md-6 mb-3">
											<label for="package">Choose Package</label>
											<select className="form-control" name="package" id="package">
												<option value="">Select Package</option>
												<option value="b80snPewA9da71c07978bf8df67f2cf01e71dca55MqPlzWrdw">
													VidFusion AI
												</option>
												<option value="nq1Jv05VK23034cced404a89fe685bd150dc2e8db0etfk7FWh">
													VidFusion AI Premium Edition
												</option>
											</select>
										</div> */}
										</div>
									</div>
									{/* <div className="col-lg-4">
										<input type="submit" name="btn_submit_block" value="Create" id="btn_submit_block"	className="btn btn-primary mt-2 mb-2"/>
											</div> */}

									<button name="btn_submit_block" type="submit" className="btn btn-primary px-4 mt-2 mb-2" id="btn_submit_block">
										Create
									</button>



								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AgencyCreate;
