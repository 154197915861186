import React, { useState, useEffect } from "react";
import "./Dashboard.styles.css";
import { Button } from "@mui/material";
import { IoHome } from "react-icons/io5";

import AvtarImg from "../../assests/images/avtar.png";
import RoboImg from "../../assests/images/robot.png";
import supportImage from "../../assests/images/ai-support-removebg-preview.png";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../GetToken";
import { handleInvalidToken } from "../../AuthUtils";
import { useNavigate, useParams, Link } from "react-router-dom";
import AnimationSpinner from "../../AnimationSpinner";


const Dashboard = (props) => {
	const navigate = useNavigate();

	const [company, setCompany] = useState(null);
	const [project, setProject] = useState("");
	const [demoVideo, setDemoVideo] = useState("");
	const [loading,setLoading] = useState(false)

	useEffect(() => {
		const fetchDashboardData = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true)
				const formData = new FormData();
				formData.append("action", "getDashbordDetails");
				formData.append("token", token);

				const response = await axios.post(
					`${props.base_url}api/user/get-user-resource`,
					formData
				);

				if (response.data.status) {
					setLoading(false)
					setCompany(response.data.company);
					setProject(response.data.project);
					setDemoVideo(response.data.video);
				} else {
					if (response.data.reset) {
						setLoading(false)
						handleInvalidToken(navigate);
					} else {
						Swal.fire({
							icon: "error",
							title: "Error",
							text: response.data.message,
						});
					}
				}
			} catch (error) {
				setLoading(false)
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			}
		};

		fetchDashboardData();
	}, [navigate, props.base_url]);

	return (
		<>
			<div className="container">
			<AnimationSpinner show={loading} />
				<div className="right-container">
					<div className="welcome-container mb-4">
						<div className="row">
							<div className="col-lg-8 col-md-12 mb-3">
								<div className="welcome-content">
									<h1>Welcome to TeamBuddy </h1>
									<p>
										An AI-powered office and HR tool that enables users to create, manage, and customize digital staff, departments, and projects effortlessly—perfect for building an AI-driven agency and unlocking new revenue streams.
									</p>

									<Link to="/profile"><Button>View more</Button></Link>
								</div>
							</div>
							<div className="col-lg-4 col-md-12 mb-3">
								<div className="assist-container">
									<div className="assist-content">
										<h3>How we can Assist You?</h3>
										<p>
											Explore our comprehensive articles and FAQs for quick
											answers to common questions.
										</p>
										<Link to="https://imtopvendor.tawk.help/" target="_blank"><Button>Support</Button></Link>

									</div>
									<div className="support-image">
										<img src={supportImage} alt="Support-img" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="quick-links-container mx-auto mb-4 ">
						<div className="row border-2 border-white">
							<Link to="/company-create" className="col-lg-3 col-md-6 col-sm-6 mb-3">
								<div className="quick-links">
									<div className="links link1">
										<div className="link-icon-container d-flex justify-content-center align-items-center p-2 shadow-md">
											<IoHome />
										</div>
										<hr />
										<div className="link-title-container d-flex justify-content-center align-items-center">
											<h5>Company</h5>
										</div>
									</div>
								</div>
							</Link>

							<Link to="/ai-staff-hiring" className="col-lg-3 col-md-6 col-sm-6 mb-3">
								<div className="quick-links">
									<div className="links link2">
										<div className="link-icon-container d-flex justify-content-center align-items-center p-2 shadow-md">
											<IoHome />
										</div>
										<hr />
										<div className="link-title-container d-flex justify-content-center align-items-center">
											<h5>Hire Staff</h5>
										</div>
									</div>
								</div>
							</Link>

							<Link to="/create-department" className="col-lg-3 col-md-6 col-sm-6 mb-3">
								<div className="quick-links">
									<div className="links link3">
										<div className="link-icon-container d-flex justify-content-center align-items-center p-2 shadow-md">
											<IoHome />
										</div>
										<hr />
										<div className="link-title-container d-flex justify-content-center align-items-center">
											<h5>Department</h5>
										</div>
									</div>
								</div>
							</Link>

							<Link to="/create-project" className="col-lg-3 col-md-6 col-sm-6 mb-3">
								<div className="quick-links">
									<div className="links link4">
										<div className="link-icon-container d-flex justify-content-center align-items-center p-2 shadow-md">
											<IoHome />
										</div>
										<hr />
										<div className="link-title-container d-flex justify-content-center align-items-center">
											<h5>Project</h5>
										</div>
									</div>
								</div>

							</Link>

						</div>
					</div>

					<div className="row">
						<div className="col-lg-8 col-md-12 mb-4">
							<div className="introduction-content">
								<h4>welcome to demo video</h4>

								<div className="embed-responsive embed-responsive-16by9">
									<iframe
										src={demoVideo}
										width="100%"
										height="360"
										frameBorder="0"
										allow="autoplay; fullscreen; picture-in-picture"
										allowFullScreen
									></iframe>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-12 mb-4">
							<div className="count-container d-flex flex-column justify-content-between align-items-center">
								<div className="count link1 mb-3">
									<div className="top-content">
										<h5>Total Company</h5>
									</div>
									<div className="botttom-content w-100 d-flex justify-content-between align-items-center mt-4">
										<div className="bottom-image-container">
											<img src={AvtarImg} alt="" />
										</div>
										<h1 className="text-white">{company}</h1>
									</div>
								</div>
								<div className="count link3 mb-4">
									<div className="top-content">
										<h5>Total Project</h5>
									</div>
									<div className="botttom-content w-100 d-flex justify-content-between align-items-center mt-4">
										<div className="bottom-image-container">
											<img src={RoboImg} alt="" />
										</div>
										<h1 className="text-white">{project}</h1>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</>
	);
};

export default Dashboard;
