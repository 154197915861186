import React from 'react'
import './WhiteLabel.styles.css'

const WhiteLabel = () => {
  return (
    <div className="right-container">
    <div className="content">
      <div className="container-fluid">
        {/* start page title  */}
        <div className="row mb-2">
          <div className="col-12 ">
            <div className="page-title-box w-100">
              <h4 className="page-title">Whitelabel</h4>
            </div>
          </div>
        </div>
        {/* end page title  */}
        {/* Card  */}
        <div className="row">
          <div className="col-md-12">
            <div className="card w-100">
              <div className="card-header">
                <h6 className="profile-heading fw-bold">Whitelabel</h6>
              </div>
              <div className="card-body">
                {/* Content */}
                <div className="row p-4">
                  <div className="col-md-12">
                    <h4 className="description-text2">
                      <span className="fw-bold">Exciting News ! </span>
                      To get TeamBuddy AI whitelabel program, please submit a
                      ticket on our support desk with code
                      ""and we will provide
                      you whitelabel access once our launch week ends. Thank
                      you for partnering with us!
                    </h4>
                  </div>
                </div>
                {/* End Content */}
              </div>
            </div>
          </div>
        </div>
        {/* End Card */}
      </div>
    </div>
  </div>
  )
}

export default WhiteLabel
