// css imports
import { useContext } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

// libraries imports
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppContext } from "./context/AppContext";

// component imports
import Loginpage from "./pages/loginPage";
import ForgotPassword from "./pages/forgotPassword";
import Dashboard from "./pages/dashboard";
import ProtectedRoute from "./ProtectedRoute";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import Footer from "./components/footer";
import CompanyCreate from "./pages/company/create";
import CompanyList from "./pages/company/cp_list";
import CompanyEdit from "./pages/company/edit";
import "bootstrap/dist/css/bootstrap.min.css";
import AIStaffHiring from "./pages/aistaff/pre_built_staff";
import Dfy from "./pages/dfy";
import Reseller from "./pages/reseller";
import WhiteLabel from "./pages/whiteLabel";
import Profile from "./pages/profile";
import ResetPassword from "./pages/resetPassword";
import TrainingVideos from "./pages/trainingVideos";
import UpGradePage from "./pages/upGrade";
import ManageStaff from "./pages/aistaff/my_staff";
import BuildCustomAgents from "./pages/customAgent/build_custom_agents";
import EditCustomAgents from "./pages/customAgent/edit_costom_agents";
import ManageAgents from "./pages/customAgent/custom_agents_lists";
import CreateDepartment from "./pages/department/create";
import ManageDepartment from "./pages/department/list";
import EditDepartment from "./pages/department/edit";
import CreateProject from "./pages/project/create";
import ManageProject from "./pages/project/list";
import EditProject from "./pages/project/edit";
import ProjectTasks from "./pages/project/projecttasks";
import AgencyCreate from "./pages/agency/create";
import AgencyList from "./pages/agency/list";
import StartConversations from "./pages/communication/create";
import ProjectChat from "./pages/communication/chats";
import ManageConversations from "./pages/communication/list";
import OpenAi from "./pages/openAI"
import StaffChat from "./pages/aistaff/staff_chat/chats"

const AppContent = () => {
	const { base_url } = useContext(AppContext);
	return (
		<>
			<Routes>
				<Route
					path="/login"
					element={
						<ProtectedRoute isLoginRoute={true}>
							<Loginpage base_url={base_url} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/forgot-password-request"
					element={
						<ProtectedRoute isLoginRoute={true}>
							<ForgotPassword />
							<Footer />
						</ProtectedRoute>
					}
				/>
				<Route
					path="*"
					element={
						<ProtectedRoute isLoginRoute={true}>
							<Loginpage base_url={base_url} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/dashboard"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<Dashboard base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/dfy"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<Dfy base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/reseller"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<Reseller base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/profile"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<Profile base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/pass-reset"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<ResetPassword base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/whitelabel"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<WhiteLabel base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/company-create"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<CompanyCreate base_url={base_url} />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/training-videos"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<TrainingVideos base_url={base_url} />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/upgrade"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<UpGradePage base_url={base_url} />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-company"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<CompanyList base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/edit-company/:companyIds"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<CompanyEdit base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/ai-staff-hiring"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<AIStaffHiring base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-staff"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<ManageStaff base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/create-department"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<CreateDepartment base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-department"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<ManageDepartment base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/edit-department/:departmentIds"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<EditDepartment base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/create-project"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<CreateProject base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-project"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<ManageProject base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/edit-project/:projectIds"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<EditProject base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/build-custom-agents"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<BuildCustomAgents base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/edit-custom-agents/:agentIds"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<EditCustomAgents base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-agents"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<ManageAgents base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/project-tasks/:projectIds"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<ProjectTasks base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>

				<Route
					path="/create-agency"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<AgencyCreate base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-agency"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className="content-wrapper">
									<AgencyList base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
			
				<Route
					path="/start-conversations"
					element={
						<ProtectedRoute>
							<Header/>
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar/>
								</div>
								<div className="content-wrapper">
									<StartConversations base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/chat/:conversationIds"
					element={
						<ProtectedRoute>
							<Header/>
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar/>
								</div>
								<div className="content-wrapper">
									<ProjectChat base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
					<Route
					path="/manage-conversations"
					element={
						<ProtectedRoute>
							<Header/>
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar/>
								</div>
								<div className="content-wrapper">
									<ManageConversations base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
					<Route
					path="/open-ai"
					element={
						<ProtectedRoute>
							<Header/>
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar/>
								</div>
								<div className="content-wrapper">
									<OpenAi base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/staff-chat/:staffIds"
					element={
						<ProtectedRoute>
							<Header/>
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar/>
								</div>
								<div className="content-wrapper">
									<StaffChat base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>

			</Routes>
		</>
	);
};

function App() {
	return (
		<BrowserRouter>
			<AppContent />
		</BrowserRouter>
	);
}

export default App;
