import React, { useState, useEffect } from "react";

import {
	Card,
	Button,
	Row,
	Col,
	Pagination,
	Form,
} from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { replace, useNavigate } from "react-router-dom";

// react-icons
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { BsChatSquareDotsFill } from "react-icons/bs";
import AnimationSpinner from "../../../AnimationSpinner";

const List = ({ base_url }) => {
	const [items, setItems] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(6);
	const [totalItems, setTotalItems] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedCompany, setSelectedCompany] = useState("");
	const [selectedDepartment, setSelectedDepartment] = useState("");
	const [selectedProject, setSelectedProject] = useState("");
	const [companies, setCompanies] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [projects, setProjects] = useState([]);
	const [selectedEditModalData, setSelectedEditModalData] = useState(null);
	const [showNameModal, setShowNameModal] = useState(false);
	const [loading, setLoading] = useState(false)

	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true)
				const formData = new FormData();
				formData.append("action", "getConversations");
				formData.append("token", token);
				formData.append("offset", currentPage);
				formData.append("limit", itemsPerPage);
				formData.append("search", searchTerm);
				formData.append("company_ids", selectedCompany);
				formData.append("department_ids", selectedDepartment);
				formData.append("project_ids", selectedProject);
				const response = await axios.post(
					`${base_url}api/user/communication-and-collaboration`,
					formData
				);

				if (response.data.status === true) {
					setLoading(false)
					setItems(response.data.items || []);
					setTotalItems(response.data.totalItems);
					setCompanies(response.data.company);
					setDepartments(response.data.department);
					setProjects(response.data.project);
				} else {
					setLoading(false)
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false)
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			}
		};

		fetchData();
	}, [currentPage, navigate, base_url, searchTerm, itemsPerPage, selectedCompany, selectedDepartment, selectedProject,showNameModal]);

	const handleUpdate = async (e, var_conversations) => {
		e.preventDefault();
		setShowNameModal(false);
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			setLoading(true)
			const formData = new FormData();
			formData.append("action", "updateConversationsDetails");
			formData.append("token", token);
			formData.append("title", var_conversations.title);
			formData.append("conversations_ids", var_conversations.ids);

			const response = await axios.post(
				`${base_url}api/user/communication-and-collaboration`,
				formData
			);
			if (response.data.status === true) {
				setLoading(false)
				setSelectedEditModalData(null);
				Swal.fire({
					icon: "success",
					title: "Detail Updated",
					text: response.data.message
				});
			} else {
				setLoading(false)
				if (response.data.reset === true) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Fauled to update conversations details. Please try again. ",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false)
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	const handleEditModal = (ids) => {
		const item = items.find((item) => item.ids === ids);
		if (item) {
			setSelectedEditModalData(item);
			setShowNameModal(true);
		}
	};
	const closeModel = () => {
		setSelectedEditModalData(null);
		setShowNameModal(false);
	};
	const handleChats = (ids) => {
		navigate(`/chat/${ids}`);

	};

	const handleDelete = async (ids) => {
		const result = await Swal.fire({
			title: "Are you sure?",
			text: "You will not be able to recover this item!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, delete it!",
		});

		if (result.isConfirmed) {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true)
				const formData = new FormData();
				formData.append("action", "removeConversations");
				formData.append("token", token);
				formData.append("conversation_ids", ids);
				const response = await axios.post(
					`${base_url}api/user/communication-and-collaboration`,
					formData
				);

				if (response.data.status === true) {
					setLoading(false)
					setItems(items.filter((item) => item.ids !== ids));
					Swal.fire("Deleted!", "Your item has been deleted.", "success");
				} else {
					setLoading(false)
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false)
				console.error("Error deleting item:", error);
				Swal.fire("Error", "Failed to delete item", "error");
			}
		}
	};

	const totalPages = Math.ceil(totalItems / itemsPerPage);

	// console.log(items);
	return (
		<div className="right-container">
			<AnimationSpinner show={loading}/>
			<div className="row">
				<div className="col-lg-12">
					<div className="form-card">
						<div className="form-body">
							<div className="head-part w-100">
								<div className="row align-items-center">
									<div className="col-lg-4 col-md-4">
										<h4 className=" title"> Manage your Communication</h4>
									</div>
									<div className="col-lg-8 col-md-8">
										<Form.Group controlId="search" className="department-search-bar d-flex align-items-center">
											<Form.Control className="custom-form-control mb-2 me-2"
												type="text"
												placeholder="Search for a conversations..."
												value={searchTerm}
												onChange={(e) => {
													setSearchTerm(e.target.value);
													setCurrentPage(1);
												}}
											/>
											<select name="company" className="form-control custom-form-control mb-2 me-2" onChange={(e) => setSelectedCompany(e.target.value)}>
												<option value="" >All</option>
												{companies.map((company) => (
													<option key={company.ids} value={company.ids}>{company.company_name}</option>
												))}
											</select>

											<select name="department" className="form-control custom-form-control mb-2 me-2" onChange={(e) => setSelectedDepartment(e.target.value)}>
												<option value="" >All</option>
												{departments.map((department) => (
													<option key={department.ids} value={department.ids}>{department.department_name}</option>
												))}
											</select>

											<select name="project" className="form-control custom-form-control mb-2 me-2" onChange={(e) => setSelectedProject(e.target.value)}>
												<option value="" >All</option>
												{projects.map((project) => (
													<option key={project.ids} value={project.ids}>{project.project_name}</option>
												))}
											</select>
										</Form.Group>



									</div>
								</div>
							</div>
							<Row>
								{items.map((item) => (
									<Col xl={4} key={item.ids} className="col-lg-6 col-md-6 mb-4">
										<Card className="company-card">
											<Card.Body>
												<Card.Title className="border-0">{item.title}</Card.Title>
												<Card.Text className="company-creation-date">
													<span>Created :</span>{" "}
													{item.created}
												</Card.Text>
												<div className="compay-card-btn-container mt-2 w-100 d-flex justify-content-start align-items-center gap-2">
													<Button
														title="Edit Project"
														variant="primary"
														onClick={() => handleEditModal(item.ids)}
													>
														<FaRegEdit /> Edit
													</Button>
													<Button title="conversations"
														variant="dark"
														onClick={() => handleChats(item.ids)}
													>
														<BsChatSquareDotsFill /> Chat
													</Button>
													<Button
														variant="danger"
														onClick={() => handleDelete(item.ids)}
														className="ml-2"
													>
														<MdDeleteForever />
														Delete
													</Button>
												</div>
											</Card.Body>
										</Card>
									</Col>
								))}
							</Row>

							<div className="pagination-container w-full d-flex justify-content-end align-items-center border-top pt-2 mt-4">
								<Pagination className="mb-0">
									<Pagination.Prev
										onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
										disabled={currentPage === 1}
									/>
									{[...Array(totalPages)].map((_, index) => (
										<Pagination.Item
											key={index + 1}
											active={index + 1 === currentPage}
											onClick={() => setCurrentPage(index + 1)}
										>
											{index + 1}
										</Pagination.Item>
									))}
									<Pagination.Next
										onClick={() =>
											setCurrentPage((prev) => Math.min(prev + 1, totalPages))
										}
										disabled={currentPage === totalPages}
									/>
								</Pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showNameModal && (
				<div className="modal d-block">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Conversation Details</h5>
								<button type="button" className="close" onClick={closeModel}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form onSubmit={(e) => handleUpdate(e, selectedEditModalData)}>
									<div className="mb-3">
										<label>Title</label>
										<input
											type="text"
											className="form-control"
											value={selectedEditModalData.title}
											onChange={(e) =>
												setSelectedEditModalData((prevData) => ({
													...prevData,
													title: e.target.value,
												}))
											}
										/>
									</div>
									<button type="submit" className="btn btn-success">
										Submit
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default List;
