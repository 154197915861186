import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, isLoginRoute }) => {
    const token = localStorage.getItem('aistafftoken'); 


	if (!token && !isLoginRoute) {
        return <Navigate to="/login" replace={true} />;
    }


    if (token && isLoginRoute) {

        return <Navigate to="/dashboard" replace={true} />;
    }

    return children; 
};

export default ProtectedRoute;
