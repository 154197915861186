import React, { useState } from "react";
import "./LoginPage.styles.css";
import { Link } from "react-router-dom";
import Logo from '../../assests/images/dark-logo.png';
import Login_Images from '../../assests/images/login.jpg';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../AnimationSpinner";

const LoginPage = (props) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate();

	const handleSubmit = (event) => {
		event.preventDefault();

		if (email.trim() === "" || password.trim() === "") {
			const text = (email.trim() === '' && password.trim() === '')
				? 'Email and Password are required.'
				: (email.trim() === '')
					? 'Email is required.'
					: 'Password is required.';

			Swal.fire({
				icon: "error",
				title: "Warning...",
				text: text,
			});
			return;
		}
		setLoading(true)
		const auth = new FormData();
		auth.append('email', email);
		auth.append('password', password);

		axios.post(`${props.base_url}authentication`, auth)
			.then(res => {
				if (res.data.status === true) {
					setLoading(false)
					localStorage.setItem('aistafftoken', res.data.token);
					localStorage.setItem('username', res.data.username);
					localStorage.setItem('avatar', res.data.avatar);
					navigate("/dashboard", { replace: true });
				} else {
					setLoading(false)
					Swal.fire({
						icon: "error",
						title: "Login Failed...",
						text: res.data.message,
					});
				}
			})
			.catch(err => {
				setLoading(false)
				// console.error('Error:', err);
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "An error occurred during login. Please try again.",
				});
			});
	};

	return (
		<>
			<section className="login-box-wrapper">
				<AnimationSpinner show={loading}/>
				<div className="container-fluid h-100">
					<div className="login-box h-100">
						<div className="row h-100 align-items-center justify-content-center">
							<div className="col-lg-4">
								<div className="login-content">
									<div className="login-head">
										<div className="login-logo">
											<img src={Logo} alt="Logo" />
										</div>
										<h2 className="title">Welcome To TeamBuddy AI</h2>
										<h6 className="sub-title">Log in with your credentials provided below</h6>
									</div>
									<form onSubmit={handleSubmit}>
									<div className="row">
										<div className="col-md-12 ">
										<div className="form-group">
											<div data-mdb-input-init className="form-outline ">
												<label htmlFor="form3Example1">Enter your Email</label>
												<input
													type="text"
													id="form3Example1"
													className="form-control"
													placeholder="Enter your Email"
													onChange={({ target: { value } }) => setEmail(value)}
												/>
											</div>
											</div>
										</div>
										<div className="col-md-12 ">
											<div className="form-group">
											<div data-mdb-input-init className="form-outline">
											<label htmlFor="form3Example2">Enter your Password</label>
												<input
													type="password"
													id="form3Example2"
													className="form-control"
													placeholder="Enter your Password"
													onChange={({ target: { value } }) => setPassword(value)}
												/>
											</div>
										</div>
										</div>
									</div>

									<div className="forgot-link">
										<Link className="text-muted" to="/forgot-password-request">
											Forgot Password?
										</Link>
									</div>
									<button
										type="submit"
										data-mdb-button-init
										data-mdb-ripple-init
										className="btn btn-primary login-button"
									>
										Login
									</button>
								</form>
								</div>
							</div>
							<div className="col-lg-8 d-none d-lg-block">
								<div className="login-media">
								<img
									src={Login_Images}
									className="w-100  shadow-4 py-10"
									alt=""
								/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default LoginPage;
