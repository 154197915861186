import React, { useState, useEffect } from "react";
import "./Create.styles.css";

import Select from "react-select";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../../AnimationSpinner";

const Create = ({ base_url }) => {
	const navigate = useNavigate();

	const [departmentName, setDepartmentName] = useState("");
	const [assignStaff, setAssignStaff] = useState([]);
	const [description, setDescription] = useState("");
	const [selectedCompany, setSelectedCompany] = useState("");
	const [logo, setLogo] = useState(null);
	const [companies, setCompanies] = useState([]);
	const [staffList, setStaffList] = useState([]);
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const fetchDataCompanyAndStaff = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true)
				const formData = new FormData();
				formData.append('action', 'getUserCompanyAndStaffLists');
				formData.append('token', token);
				formData.append('selected_company', selectedCompany);
				const response = await axios.post(`${base_url}user/api/process-resource`, formData);

				if (response.data.status) {
					setLoading(false)
					if (response.data.items === '') {
						Swal.fire({
							icon: "error",
							title: "Error",
							text: "Please Create Company First",
						});
						return;
					}
					

					const companyOptions = response.data.companies.map((company) => ({
						value: company.ids,
						label: company.company_name,
					}));
					setCompanies(companyOptions || []);
					const staffOptions = response.data.staff.map((staff) => ({
						value: staff.ids,
						label: staff.name,
					}));
					setStaffList(staffOptions || []);
				} else {
					setLoading(false)
					if (response.data.reset) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false)
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			}
		};

		fetchDataCompanyAndStaff();
	}, [navigate, base_url, selectedCompany]);

	const handleLogoChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			setLogo(file);
		}
	};

	function Sweetalert(field) {
		Swal.fire({
			icon: "error",
			title: "Validation Error",
			text: field + " is required!",
		});
	}
	const handleSubmit = (event) => {
		event.preventDefault();

		var token = GetToken();

		if (!token) {
			handleInvalidToken(navigate);
			return;
		}

		let isValid = true;
		if (!departmentName && !assignStaff && !logo && !selectedCompany) {
			Sweetalert("Department Name, Assign Staff , Logo & Company");
			isValid = false;
		} else {
			if (!departmentName) {
				Sweetalert("Department Name");
				isValid = false;
			}
			if (!assignStaff) {
				Sweetalert("Assign Staff");
				isValid = false;
			}
			if (!logo) {
				Sweetalert("Logo");
				isValid = false;
			}
			if (!selectedCompany) {
				Sweetalert("Company");
				isValid = false;
			}
		}
		if (!isValid) {
			return;
		}

		const selectedStaff = staffList.filter(staff => assignStaff.includes(staff.value));
		setLoading(true)
		const formData = new FormData();
		formData.append("department_name", departmentName);
		formData.append("assign_staff", JSON.stringify(selectedStaff));
		// formData.append("assign_staff", assignStaff);
		formData.append("description", description);
		formData.append("department_logo", logo);
		formData.append("company_ids", selectedCompany);
		formData.append("token", token);
		formData.append("action", "createDepartment");

		axios
			.post(`${base_url}user/api/process-resource`, formData)
			.then((response) => {
				if (response.data.status === true) {
					setLoading(false)
					Swal.fire({
						icon: "success",
						title: "Department Added",
						text: "Your department details have been added successfully.",
					});
					navigate("/manage-department", { replace: true });
				} else {
					setLoading(false)
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			})
			.catch((error) => {
				setLoading(false)
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			});
	};

	return (
		<Container className="h-100">
			<AnimationSpinner show={loading}/>
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card">
							<div className="form-body">
								<div className="head-part">
									<h4 className=" title">Add Department Details</h4>
								</div>
								<form onSubmit={handleSubmit}>
									<div className="row w-100">
										<div className="col-lg-4 col-md-6 mb-4">
											<label htmlFor="formDepartmentName" className="form-label">
												Department Name
											</label>
											<input
												type="text"
												id="formDepartmentName"
												className="form-control custom-form-control"
												placeholder="Enter department name"
												value={departmentName}
												onChange={(e) => setDepartmentName(e.target.value)}
											/>
										</div>
										<div className="col-lg-4 col-md-6 mb-4">
											<label htmlFor="formDepartmentName" className="form-label">
												Select Company
											</label>
											<Select
												name="company"
												options={companies}
												value={companies.find(option => option.value === selectedCompany)}
												onChange={(selectedOption) => setSelectedCompany(selectedOption.value)}
												classNamePrefix="form-control-style"
											/>
											{/* <select
												name="company"
												className="form-control"
												value={selectedCompany}
												onChange={(e) => setSelectedCompany(e.target.value)}
											>
												<option value="">Select Company</option>
												{companies.map((company) => (
													<option key={company.ids} value={company.ids}>
														{company.company_name}
													</option>
												))}
											</select> */}
										</div>
										<div className="col-lg-4 col-md-12 mb-4">
											<label htmlFor="formDepartmentName" className="form-label">
												Select AI Staff
											</label>

											<Select
												isMulti
												name="Staff"
												onChange={(selectedOptions) =>
													setAssignStaff(selectedOptions.map(option => option.value))
												}
												options={staffList}
												classNamePrefix="form-control-style"
											/>

											{/* <select
												name="staff"
												className="form-control"
												multiple
												onChange={(e) =>
													setAssignStaff(
														Array.from(
															e.target.selectedOptions,
															(option) => option.value
														)
													)
												}
											>
												<option value="" disabled>
													Select AI Staff
												</option>
												{staffList.map((staff) => (
													<option key={staff.ids} value={staff.ids}>
														{staff.name}
													</option>
												))}
											</select> */}
										</div>
									</div>

									<div className="mb-4">
										<label htmlFor="formDescription" className="form-label">
											Description
										</label>
										<textarea
											id="formDescription"
											className="form-control custom-form-control"
											style={{ height: "100px" }}
											placeholder="Enter company description"
											value={description}
											onChange={(e) => setDescription(e.target.value)}
										/>
									</div>

									<div className="mb-4">
										<label htmlFor="formLogo" className="form-label">
											Department Logo
										</label>
										<input
											type="file"
											id="formLogo"
											accept=".jpg,.png,.jpeg"
											className="form-control form-radius"
											onChange={handleLogoChange}
										/>
									</div>

									<button type="submit" className="btn btn-primary mt-2 mb-3">
										Add Department
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Create;
