import React, { useState, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import "./Sidebar.styles.css";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { FaTools } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";
import { FiLogOut } from "react-icons/fi";
import Swal from "sweetalert2";
import { handleInvalidToken } from "../../AuthUtils";
import { RiDashboardHorizontalFill } from "react-icons/ri";
import { BsBuildingsFill } from "react-icons/bs";
import { FaGifts } from "react-icons/fa";
import { FaHandHoldingUsd } from "react-icons/fa";
import { FaWandSparkles } from "react-icons/fa6";
import { IoMdSettings } from "react-icons/io";
import { FaDesktop } from "react-icons/fa6";
import { MdOutlineSupportAgent } from "react-icons/md";
import { GiUpgrade } from "react-icons/gi";
import { FaUserPlus } from "react-icons/fa6";
// import { FcDepartment } from "react-icons/fc";
// import { GoProject } from "react-icons/go";
import { AiFillProject } from "react-icons/ai";
// import { BiMessageSquareDetail } from "react-icons/bi";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { FaBuilding } from "react-icons/fa";

const Sidebar = () => {
	const { isSidebarOpen, setIsSidebarOpen,isSmallScreen } = useContext(AppContext);
	const [activeTab, setActiveTab] = useState(null);
	const navigate = useNavigate();

	const handleLogout = () => {
		Swal.fire({
			title: "Are you sure you want to log out?",
			icon: "warning",
			showDenyButton: true,
			showCancelButton: true,
			confirmButtonText: "Yes, log me out",
			cancelButtonText: "Cancel",
			denyButtonText: "No, stay logged in",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire(
					"Logged out",
					"You have been successfully logged out.",
					"success"
				);
				handleInvalidToken(navigate);
			} else if (result.isDenied) {
				Swal.fire("Stayed logged in", "You chose to stay logged in.", "info");
			}
		});
	};
	const handleSubmenuOpen = (index) => {
		setActiveTab(activeTab === index ? null : index);
	};
	
	// -------------------------------------------------------------------------------
	const handleItemClick = (hasSubmenu) => {
		if(isSidebarOpen){
			if (isSmallScreen && !hasSubmenu) {
				setIsSidebarOpen(false);
			}
		}
		else{
			setIsSidebarOpen(true)
		}
	}
	const handleSubmenuItemClick = () => {
		if (isSmallScreen) {
			setIsSidebarOpen(false);
		}
	}
	return (
		<div className={`sidebar transition ${isSidebarOpen ? "toogleOpen" : "toggleClose"}`}>
			<ul className="title mb-0 pe-0">
				{[
					{
						icon: <RiDashboardHorizontalFill />,
						label: "Dashboard",
						labelUrl: "/dashboard",
					},
					{
						icon: <BsBuildingsFill />,
						label: "Company",
						submenu: [
							{ title: "Create", url: "/company-create" },
							{ title: "Manage", url: "/manage-company" },
						],
					},
					{
						icon: <FaUserPlus />,
						label: "AI Staff",
						submenu: [
							{ title: "Hire", url: "/ai-staff-hiring" },
							{ title: "My Staff", url: "/manage-staff" },
						],
					},
					{
						// icon: <FcDepartment />,
						icon: <FaBuilding />,
						label: "Department",
						submenu: [
							{ title: "Create", url: "/create-department" },
							{ title: "Manage", url: "/manage-department" },
						],
					},
					{
						// icon: <GoProject />,
						icon: <AiFillProject />,
						label: "Project",
						submenu: [
							{ title: "Create", url: "/create-project" },
							{ title: "Manage", url: "/manage-project" },
						],
					},
					{
						// icon: <BiMessageSquareDetail />,
						icon: <BiSolidMessageSquareDetail />,
						label: "Communication",
						submenu: [
							{ title: "Create", url: "/start-conversations" },
							{ title: "Manage", url: "/manage-conversations" },
						],
					},
					{
						// icon: <GoProject />,
						icon: <AiFillProject />,
						label: "Custom AI Agent",
						submenu: [
							{ title: "Create", url: "/build-custom-agents" },
							{ title: "Manage", url: "/manage-agents" },
						],
					},
					{ icon: <FaGifts />, label: "DFY Edition", labelUrl: "/dfy" },

					{
						icon: <FaHandHoldingUsd />,
						label: "Reseller",
						labelUrl: "/reseller",
					},
					{
						icon: <FaWandSparkles />,
						label: "Whitelabel Edition",
						labelUrl: "/whitelabel",
					},
					{
						icon: <FaTools />,
						label: "Agency",
						submenu: [
							{ title: "Create", url: "/create-agency" },
							{ title: "Manage", url: "/manage-agency" },
						],
					},
					{
						icon: <IoMdSettings />,
						label: "Open AI Settings",
						labelUrl: "/open-ai",
					},
					{
						icon: <FaDesktop />,
						label: "Training Videos",
						labelUrl: "/training-videos",
					},
					{
						icon: <MdOutlineSupportAgent />,
						label: "Support Desk",
						targetBlank: "_blank",
						labelUrl: "https://imtopvendor.tawk.help/",
					},
					{ icon: <GiUpgrade />, label: "Upgrade Page", labelUrl: "/upgrade" },
				].map((item, index) => (
					<li key={index}>
						<Link to={item.labelUrl && isSidebarOpen ? item.labelUrl : null} onClick={() => handleItemClick(!!item.submenu)}>
							<Button
								className={`w-100 text-start justify-content-start align-items-center text-capitalize ${
									activeTab === index ? "active" : ""
								}`}
								onClick={() => handleSubmenuOpen(index)}
							>
								<span
									className="icon d-flex align-items-center justify-content-center"
								>
									{item.icon}
								</span>
								{isSidebarOpen ? (
									<>
										{/* item lebel url + arrow  start*/}
										{item.label}
										{item.submenu && (
											<span className="arrow d-flex align-items-center justify-content-center">
												<FaAngleRight />
											</span>
										)}
										{/* item label url plus arrow end */}
									</>
								) : null}
							</Button>
						</Link>

						{/* Render submenu only if it exists */}
						{item.submenu && isSidebarOpen ? (
							<div
								className={`subMenu-wrapper transition ${
									activeTab === index ? "open" : "close"
								}`}
							>
								<div className="subMenu transition">
									<ul className="p-0">
										{item.submenu.map((subitem, subIndex) => (
											<li key={subIndex}>
												<Link to={subitem.url}  onClick={handleSubmenuItemClick}>{subitem.title}</Link>
											</li>
										))}
									</ul>
								</div>
							</div>
						) : null}
					</li>
				))}
			</ul>

			<div className="logout-wrapper">
				<div className="logout-box d-flex align-items-center justify-content-center">
					<Button
						variant="contained"
						onClick={handleLogout}
						className="btn btn-primary"
					>
						<FiLogOut /> Logout
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
