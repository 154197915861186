import React, { createContext, useState, useEffect } from 'react';

export const AppContext = createContext();

// provider component
export const AppProvider = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); 
    const[isSmallScreen , setIsSmallScreen] = useState(false);

	const base_url = window.location.hostname === "localhost"
	? "http://localhost/aistaff/"
	: "https://teambuddyai.com/api/";

    const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);

    // Toggle the sidebar based on screen width
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setIsSidebarOpen(false); // Close sidebar on small screens
                setIsSmallScreen(true);
            } else {
                setIsSidebarOpen(true); // Open sidebar on larger screens
                setIsSmallScreen(false);
            }
        };

        // Initial check and add event listener for resizing
        handleResize();
        window.addEventListener("resize", handleResize);
        
        // Cleanup event listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <AppContext.Provider value={{ isSidebarOpen, isSmallScreen, toggleSidebar, setIsSidebarOpen, base_url }}>
            {children}
        </AppContext.Provider>
    );
};
