import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import AnimationSpinner from "../../../AnimationSpinner";

const Edit = (props) => {
	const navigate = useNavigate();
	const { projectIds } = useParams();
	const [projectName, setProjectName] = useState("");
	const [assignStaff, setAssignStaff] = useState([]);
	const [description, setDescription] = useState("");
	const [projectStatus, setProjectStatus] = useState("");
	const [companies, setCompanies] = useState("");
	const [staffList, setStaffList] = useState([]);
	const [department, setDepartment] = useState("");
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const fetchEditData = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true)
				const formData = new FormData();
				formData.append("action", "getEditProjectDetails");
				formData.append("token", token);
				formData.append("project_ids", projectIds);

				const response = await axios.post(
					`${props.base_url}user/api/process-resource`,
					formData
				);

				if (response.data.status) {
					setLoading(false)
					setCompanies(response.data.company.company_name);
					setProjectName(response.data.items.project_name || "");
					setDescription(response.data.items.description || "");
					setProjectStatus(response.data.items.project_status);
					setDepartment(response.data.department.department_name);
					setAssignStaff(response.data.assign_staff);
					const staffOptions = response.data.staff.map((staff) => ({
						value: staff.assign_staff_ids,
						label: staff.assign_staff_name,
					}));
					setStaffList(staffOptions || []);
				} else {
					setLoading(false)
					if (response.data.reset) {
						handleInvalidToken(navigate);
					} else {
						Swal.fire({
							icon: "error",
							title: "Error",
							text: response.data.message,
						});
					}
				}
			} catch (error) {
				setLoading(false)
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			}
		};

		fetchEditData();
	}, [projectIds, navigate, props.base_url]);


	function Sweetalert(field) {
		Swal.fire({
			icon: "error",
			title: "Validation Error",
			text: field + " is required!",
		});
	}

	const handleSubmit = async (event) => {
		event.preventDefault();

		const token = GetToken();
		if (!token) {
			handleInvalidToken(navigate);
			return;
		}

		let isValid = true;
		if (!projectName && !assignStaff) {
			Sweetalert("Project Name& Assign Staff");
			isValid = false;
		} else {
			if (!projectName) {
				Sweetalert("Project Name");
				isValid = false;
			}
			if (!assignStaff.length) {
				Sweetalert("Assign Staff");
				isValid = false;
			}

		}
		if (!isValid) {
			return;
		}

		const selectedStaff = staffList.filter(staff => assignStaff.includes(staff.value));
		setLoading(true)
		const formData = new FormData();

		formData.append("edit_project_ids", projectIds);
		formData.append("project_name", projectName);
		formData.append("assign_staff", JSON.stringify(selectedStaff));
		formData.append("description", description);
		formData.append("project_status", projectStatus);
		formData.append("token", token);
		formData.append("action", "updateProject");

		try {
			const response = await axios.post(
				`${props.base_url}user/api/process-resource`,
				formData
			);

			if (response.data.status) {
				setLoading(false)
				Swal.fire({
					icon: "success",
					title: "Project Updated",
					text: "Your project details have been updated successfully.",
				});
				navigate("/manage-project", { replace: true });
			} else {
				setLoading(false)
				if (response.data.reset) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false)
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	return (
		<Container className="h-100">
			<AnimationSpinner show={loading}/>
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card ">
							<div className=" form-body">
								<div className="head-part">
									<h4 className=" title">Edit Project Details</h4>
								</div>
								<form onSubmit={handleSubmit}>
									<div className="row w-100">
										<div className="col-md-6 mb-4">
											<label htmlFor="formProjectName" className="form-label">
												Project Name
											</label>
											<input
												type="text"
												id="formProjectName"
												className="form-control custom-form-control"
												placeholder="Enter project name"
												value={projectName}
												onChange={(e) => setProjectName(e.target.value)}
											/>
										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="formCompanyName" className="form-label">
												Company
											</label>
											<input
												readOnly
												type="text"
												className="form-control custom-form-control"
												placeholder="Enter Company name"
												value={companies}
											/>
										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="formDepartmentName" className="form-label">
												Department
											</label>
											<input
												readOnly
												type="text"
												className="form-control custom-form-control"
												placeholder="Enter Department name"
												value={department}
											/>
										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="formStaffName" className="form-label">
												Select AI Staff
											</label>

											<Select
												isMulti
												value={staffList.filter(staff => assignStaff.includes(staff.value))}
												name="Staff"
												onChange={(selectedOptions) =>
													setAssignStaff(selectedOptions ? selectedOptions.map(option => option.value) : [])
												}
												options={staffList}
											/>

										</div>
										<div className="col-md-6 mb-4">
											<label htmlFor="formProjectStatus" className="form-label">
												Select Project Status
											</label>
											<select
												className="form-control custom-form-control"
												name="project_status"
												value={projectStatus || ""} 
												onChange={(e) => setProjectStatus(e.target.value)}
											>
												<option value="active">Active</option>
												<option value="inactive">Inactive</option>
												<option value="complete">Complete</option>
											</select>
										</div>

									</div>

									<div className="mb-4">
										<label htmlFor="formDescription" className="form-label">
											Description
										</label>
										<textarea
											id="formDescription"
											className="form-control custom-form-control"
											style={{ height: '100px' }}
											placeholder="Enter description"
											value={description}
											onChange={(e) => setDescription(e.target.value)}
										/>
									</div>

									<button type="submit" className="btn btn-primary mt-1 mb-3">
										Update Project
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Edit;
